import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  wrapper: {
    paddingRight: '8px', // grid gutters
    paddingBottom: '8px', // grid gutters
  },
  cardWrapper: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      paddingBottom: '32px',
      borderRadiusBottom: '0',
    },
  },
  price: {
    fontSize: '14px',
    width: '100%',
  },
  footerPriceMobile: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      background: 'white',
      padding: '16px',
    },
  },
}))
