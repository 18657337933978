import { IconDownload } from '@tabler/icons'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Card } from 'primereact/card'
import { Column } from 'primereact/column'
import { DataTable, DataTableExpandedRows, DataTableStateEvent } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import { Nullable } from 'primereact/ts-helpers'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { Badge } from '@/components/Elements'
import { ModalOrDialogSwitch } from '@/components/Elements/MobileWebSwitchs/ModalOrDialogSwitch'
import { useAppState } from '@/features/app/hooks'
import { calendarsAPI } from '@/features/calendar/api'
import { useHeaderContext } from '@/features/header/contexts/HeaderContext'
import { WalletInfo } from '@/features/wallet/components'
import { downloadFile } from '@/helpers/common/utils'
import { Booking } from '@/interfaces/Booking'
import { CheckIn } from '@/interfaces/CheckIn'
import { formatAmount } from '@/utils/format'
import { UploadInvoiceDialog } from './UploadInvoiceDialog'
import useStyles from './WalletView.styles'

export const WalletViewBabysitter = () => {
  const { t } = useTranslation()

  const [events, setEvents] = useState([])

  const theme = useMantineTheme()
  const { classes } = useStyles()
  const { setKey } = useHeaderContext()
  const toast = useRef<Toast>(null)

  const [expandedRows, setExpandedRows] = useState<any[] | DataTableExpandedRows>([])
  const [bookingExpandedRows, setBookingExpandedRows] = useState<any[] | DataTableExpandedRows>([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(10)
  const [sortField, setSortField] = useState<any>(null)
  const [sortOrder, setSortOrder] = useState<any>(null)
  const [loading, setLoading] = useState<any>(false)
  const [invoiceAmount, setInvoiceAmount] = useState<any>(0)
  const [dates, setDates] = useState<Nullable<(Date | null)[]>>([
    moment().startOf('month').toDate(),
    moment().endOf('month').toDate(),
  ])
  const [visible, setVisible] = useState(false)

  const {
    appState: { mobileView },
  } = useAppState()

  const getWalletEvents = async (params?: any) => {
    try {
      setLoading(true)
      const { data } = await calendarsAPI.getCheckins(params)
      setEvents(data.data)
      setTotalRecords(data.total)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getInvoiceAmount = async (params?: any) => {
    try {
      const { data } = await calendarsAPI.getInvoiceAmount({})
      setInvoiceAmount(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getWalletEvents({ sortField, sortOrder, first, rows, dates })
  }, [sortField, sortOrder, first, rows, dates])

  useEffect(() => {
    setKey({ base: true, title: 'wallet', border: true })
    if (!mobileView) getInvoiceAmount()
  }, [])

  const rowExpansionTemplate = (data: CheckIn) => {
    return (
      <div className="p-0 flex gap-4 items-center justify-between">
        <h5>Morning hours: {data.morning_hours}€</h5>
        <h5>Morning price: {data.morning_price.bbs_city_price.amount}€</h5>
        <h5>Morning amount: {+data.morning_hours * +data.morning_price.bbs_city_price.amount}€</h5>
        <h5>Evening hours: {data.evening_hours}€</h5>
        <h5>Evening price: {data.evening_price.bbs_city_price.amount}€</h5>
        <h5>Evening amount: {+data.evening_hours * +data.evening_price.bbs_city_price.amount}€</h5>
      </div>
    )
  }

  const bookingRowExpansionTemplate = (data: Booking) => {
    return (
      <DataTable
        value={data.check_ins}
        dataKey="id"
        tableStyle={{ minWidth: '60rem' }}
        paginator={totalRecords > 10}
        rows={rows}
        rowsPerPageOptions={[10, 20, 50, 70]}
        removableSort
        totalRecords={totalRecords}
        first={first}
        onPage={onPage}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSort}
        sortMode="single"
        loading={loading}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column expander style={{ width: '5rem' }} />
        <Column field="check_in_starts_at" header={t('service_date')} sortable body={serviceDate} />
        <Column field="check_in_ends_at" header={t('service_time')} sortable body={serviceTime} />
        <Column field="total_price" header={t('price')} body={price} sortable />
        <Column field="total_amount" header={t('amount')} body={amount} sortable />
        <Column field="status" header={t('receipt_status')} body={receipt} />
        <Column field="actions" header={t('actions')} body={actions} />
      </DataTable>
    )
  }

  const serviceDate = (checkIn: CheckIn) => {
    return moment(checkIn.check_in_starts_at).format('LL')
  }

  const bookingServiceDate = (booking: Booking) => {
    return `${moment(booking.date_start).format('LL')} - ${moment(booking.date_end).format('LL')}`
  }

  const serviceTime = (checkIn: CheckIn) => {
    return `${moment(checkIn.check_in_starts_at).format('LT')} - ${moment(
      checkIn.check_in_ends_at
    ).format('LT')}`
  }

  const amount = (checkIn: CheckIn) => {
    return formatAmount(checkIn.total_amount)
  }

  const price = (checkIn: CheckIn) => {
    return formatAmount(checkIn.total_price)
  }

  const BookingAmount = (checkIn: Booking) => {
    return formatAmount(checkIn.bbs_total)
  }

  const BookingPrice = (checkIn: Booking) => {
    return formatAmount(checkIn.bbs_total_price)
  }

  const onPage = (event: DataTableStateEvent) => {
    setFirst(event.first)
    setRows(event.rows)
  }

  const onSort = (event: DataTableStateEvent) => {
    setSortField(event.sortField)
    setSortOrder(event.sortOrder)
  }

  const onUploadSuccess = () => {
    toast?.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Your invoice submitted successfully!',
    })
  }

  const receipt = (checkIn: CheckIn) => {
    return checkIn.babysitter_invoice_id ? (
      <Badge className={classes.badge} color={theme.colors.secondary[4]} variant={'filled'}>
        {checkIn.babysitter_invoice.status}
      </Badge>
    ) : (
      <Badge
        className={classes.badge}
        color={theme.colors.primary[theme.fn.primaryShade()]}
        variant={'filled'}
      >
        {t('not_uploaded')}
      </Badge>
    )
  }

  const bookingStatus = ({ status }: Booking) => {
    return (
      <Badge
        className={classes.badge}
        color={theme.colors.primary[theme.fn.primaryShade()]}
        variant={'filled'}
      >
        {status}
      </Badge>
    )
  }

  const downloadInvoice = async ({ id, babysitter_invoice }: CheckIn) => {
    const { data } = await calendarsAPI.downloadInvoice(id)

    downloadFile(data, babysitter_invoice.file)
  }

  const renderEvent = (event: any) =>
    event.check_ins.map((item: any, j: number) => (
      <Group key={j} className={classes.groupItem}>
        <Text className={classes.groupItemLabel}>
          {serviceDate(item) + ', ' + serviceTime(item)}
        </Text>
        <Text className={classes.groupItemValue}>{amount(item)}</Text>
        {receipt(item)}
      </Group>
    ))

  const actions = (checkIn: CheckIn) => {
    return (
      checkIn.babysitter_invoice_id && (
        <Button
          onClick={() => downloadInvoice(checkIn)}
          text
          tooltip="Download your receipt"
          severity="success"
        >
          <i className="pi pi-cloud-download"></i>
        </Button>
      )
    )
  }

  return (
    <div className="flex flex-col gap-8">
      <WalletInfo />
      <div>
        <div className="flex justify-between items-center">
          <Title order={mobileView ? 5 : 2}>{t('receipts')}</Title>
          {mobileView ? (
            <Group
              className="gap-2"
              onClick={() => {
                if (events && events.length > 0) setVisible(true)
              }}
            >
              <IconDownload
                size={20}
                color={
                  theme.colors.primary[events && events.length > 0 ? theme.fn.primaryShade() : 3]
                }
              />
              <Text
                color={
                  theme.colors.primary[events && events.length > 0 ? theme.fn.primaryShade() : 3]
                }
                size={16}
                fw={700}
              >
                {t('upload_invoice')}
              </Text>
            </Group>
          ) : (
            <Button
              onClick={() => setVisible(true)}
              className="p-button-success flex gap-3"
              disabled={!events.length}
            >
              <i className="pi pi-upload"></i>
              <span>{t('upload_invoice')}</span>
            </Button>
          )}

          <ModalOrDialogSwitch
            title={t('upload_invoice')}
            opened={visible}
            headerBorder={true}
            onClose={() => setVisible(false)}
          >
            <UploadInvoiceDialog
              visibilityChange={(param: boolean) => setVisible(param)}
              onUploadSuccess={onUploadSuccess}
            />
          </ModalOrDialogSwitch>
        </div>

        <div className={mobileView ? 'flex flex-col gap-4 mt-3' : 'flex flex-col gap-4'}>
          <div className="flex w-full justify-between items-center">
            <Calendar
              value={dates}
              onChange={(e) => setDates(e.value)}
              selectionMode="range"
              readOnlyInput
              dateFormat="dd/mm/yy"
              showIcon
              iconPos="left"
              showButtonBar
              className="w-64"
            />

            {!mobileView && (
              <div className="text-2xl">
                {t('invoice_amount')}: <span className=" font-bold">€{invoiceAmount}</span>
              </div>
            )}
          </div>

          {mobileView ? (
            <Stack className={'w-100 gap-2.5'}>
              {events.map((event: any, i: number) => (
                <React.Fragment key={i}>{renderEvent(event)}</React.Fragment>
              ))}
            </Stack>
          ) : (
            <Card
              className="p-0"
              pt={{
                body: { className: 'p-0' },
                content: { className: 'p-0' },
              }}
            >
              <DataTable
                value={events}
                dataKey="id"
                tableStyle={{ minWidth: '60rem' }}
                paginator={totalRecords > 10}
                rows={rows}
                rowsPerPageOptions={[10, 20, 50, 70]}
                removableSort
                totalRecords={totalRecords}
                first={first}
                onPage={onPage}
                sortField={sortField}
                sortOrder={sortOrder}
                onSort={onSort}
                sortMode="single"
                loading={loading}
                expandedRows={bookingExpandedRows}
                onRowToggle={(e) => setBookingExpandedRows(e.data)}
                rowExpansionTemplate={bookingRowExpansionTemplate}
              >
                <Column expander style={{ width: '5rem' }} />
                <Column field="id" header={t('booking_id')} />
                <Column
                  field="check_in_starts_at"
                  header={t('service_date')}
                  sortable
                  body={bookingServiceDate}
                />
                <Column field="bbs_total_price" header={t('price')} body={BookingPrice} sortable />
                <Column field="total_amount" header={t('amount')} body={BookingAmount} sortable />
                <Column field="status" header={t('booking_status')} body={bookingStatus} />
              </DataTable>
            </Card>
          )}
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  )
}
