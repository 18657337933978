import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { bookingAPI } from '@/features/bookings/api'
import { SubmitBabysitterRatingDTO } from '@/features/rating/api/dto'
import { FormTypes } from '@/types'
import {
  BOOKINGS_RATING_BABYSITTER_CREATE_FAILED,
  BOOKINGS_RATING_BABYSITTER_CREATE_REQUEST,
  BOOKINGS_RATING_BABYSITTER_CREATE_SUCCESS,
} from './actionTypes'

type ArgsType = {
  id: number
  params: SubmitBabysitterRatingDTO
}

export const bookingsRatingBabysitterCreatePA = createPromiseAction(
  BOOKINGS_RATING_BABYSITTER_CREATE_REQUEST,
  BOOKINGS_RATING_BABYSITTER_CREATE_SUCCESS,
  BOOKINGS_RATING_BABYSITTER_CREATE_FAILED
)<ArgsType, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* create(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { id, params } = action.payload

    const response: AxiosResponse = yield call(bookingAPI.createBabysitterRating, id, params)
    const { data } = response

    yield put(bookingsRatingBabysitterCreatePA.success({}))

    resolvePromiseAction(action, { ...data?.data })
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* createSaga() {
  yield takeEvery(bookingsRatingBabysitterCreatePA.request, create)
}
