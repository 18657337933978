import React from 'react'
import { useAppState } from '@/features/app/hooks'
import { BookingModel } from '@/features/bookings/types/models'
import { ActionBar } from './ActionBar'
import { AlertInfoBlock } from './AlertInfoBlock'
import useStyles from './Card.styles'
import { DateBlock } from './DateBlock'
import { Footer } from './Footer'
import { InfoBlock } from './InfoBlock'

interface IProps {
  data: BookingModel
  onView: (id: number) => void
  onCancelSuccess?: () => void
  actionBar?: boolean
  actionBarSection?: React.ReactNode
  actionBarProps?: any
  footer?: boolean
  footerProps?: any
  favoriteControlProps?: any
  ratingControlProps?: any
}

export const GeneralBookingCard = ({
  data,
  onView,
  onCancelSuccess,
  actionBar = true,
  actionBarSection,
  actionBarProps,
  footer = true,
  footerProps,
  favoriteControlProps,
  ratingControlProps,
}: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  const { classes } = useStyles()

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    if (mobileView) {
      onView(data.id)
    }
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.dateBlock} onClick={onClick}>
            <DateBlock data={data} />
          </div>
          <div className={classes.infoBlock} onClick={onClick}>
            <InfoBlock data={data} />
          </div>
          {actionBar && (
            <div className={classes.actionBar}>
              {actionBarSection ? (
                actionBarSection
              ) : (
                <ActionBar
                  booking={data}
                  {...actionBarProps}
                  onCancelSuccess={onCancelSuccess}
                  favoriteControlProps={favoriteControlProps}
                  ratingControlProps={ratingControlProps}
                />
              )}
            </div>
          )}
        </div>

        <AlertInfoBlock data={data} />

        {footer && !mobileView && (
          <div className={classes.footer}>
            <Footer
              booking={data}
              {...footerProps}
              onView={onView}
              ratingControlProps={ratingControlProps}
            />
          </div>
        )}
      </div>
    </>
  )
}
