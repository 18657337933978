import React from 'react'
import { ChatNavControl } from '@/features/chat/components/Elements'
import { ChatModel } from '@/features/chat/types/models'
import { ClientUser } from '@/features/user'

interface IProps {
  client: ClientUser
  chat?: ChatModel
}

export const ChatControl = ({ client, chat }: IProps) => {
  const { id, avatar, first_name, last_name, role } = client

  //console.log('ChatControl #2', client, chat)

  return <ChatNavControl chat={chat} participant={{ id, avatar, first_name, last_name, role }} />
}
