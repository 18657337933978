import classNames from 'classnames'
import * as React from 'react'
import { Group, Header as MantineHeader } from '@mantine/core'
import { AppLangPicker, LogoIcon } from '@/components/Elements'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { MobileBackLink } from '@/features/header/layout/BackLink/MobileBackLink'
import { Info } from '@/features/header/layout/Info'
import { Support } from '@/features/header/layout/Support'
import useStyles from './MobileHeader.styles'

interface IProps {
  bookingType?: BOOKING_TYPE
}

export const MobileHeaderBooking = ({ bookingType = undefined }: IProps) => {
  const { classes } = useStyles()

  return (
    <MantineHeader
      height={60}
      p="xs"
      styles={(theme) => ({
        root: {
          backgroundColor: 'transparent',
          border: 'none',
          display: 'flex',
        },
      })}
      className={classes.noborder}
    >
      <Group position="apart" className={'w-100 gap-0'}>
        <div className={bookingType ? classes.third : classes.half}>
          <div className={classes.itemsLeft}>
            {bookingType ? <MobileBackLink goToBookNow={true} /> : <LogoIcon size={'sm'} />}
            <div className={classes.flag}>
              <AppLangPicker />
            </div>
          </div>
        </div>
        {bookingType && (
          <div className={classNames('text-center', classes.third)}>
            <LogoIcon size={'sm'} />
          </div>
        )}
        <div
          className={classNames(bookingType ? classes.third : classes.half, classes.headerRight)}
        >
          {bookingType && <Info bookingType={bookingType} />}
          <div className={classes.marginSupport}>
            <Support />
          </div>
        </div>
      </Group>
    </MantineHeader>
  )
}
