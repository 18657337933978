import React from 'react'
import { Link } from 'react-router-dom'
import { Anchor, Group, Text } from '@mantine/core'
import { AvatarProvider, Icon } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { BookingTimePeriodDisplay } from '@/features/bookings/components/Elements'
import { bookingHelper } from '@/features/bookings/helpers'
import { BookingModel } from '@/features/bookings/types/models'
import { useUsersModel } from '@/features/users/hooks'
import useStyles from './InfoBlock.styles'

const { bookingModel } = bookingHelper
const getType = bookingHelper.type.getType

interface IProps {
  data: BookingModel
}

export const InfoBlock = ({ data }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  const { classes } = useStyles()

  const { babysitter, type } = data

  const timePeriod = bookingModel.getTimePeriod(data)

  const { getName } = useUsersModel()

  return (
    <div className={classes.root}>
      {!mobileView && timePeriod && (
        <Text size={'xl'} weight={700}>
          <BookingTimePeriodDisplay timePeriod={timePeriod} />
        </Text>
      )}

      <div className={'flex gap-2 items-center'}>
        {babysitter && (
          <Group spacing={8} noWrap>
            <Anchor component={Link} to={`/babysitters/${babysitter?.id}`}>
              <AvatarProvider size={mobileView ? 30 : 32} avatar={babysitter?.avatar} />
            </Anchor>

            <Anchor component={Link} to={`/babysitters/${babysitter?.id}`} color={'gray'}>
              <Text size={mobileView ? 14 : 18} weight={600} color={'gray.6'}>
                {getName(babysitter)}
              </Text>
            </Anchor>
          </Group>
        )}

        <Icon name={getType(type)?.iconName} size={mobileView ? 18 : 23} />
      </div>
    </div>
  )
}
