import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Title } from '@mantine/core'
import { LanguagesGroupFields } from '@/features/profile/components/FormElements'
import { ClientProfile, useUser } from '@/features/user'

interface IProps {
  boldTitle?: boolean
}
export const LanguagesFormSection = ({ boldTitle = false }: IProps) => {
  const { t } = useTranslation()
  const { watch } = useFormContext()

  const { user } = useUser()
  const { client_profile } = user || ({} as any)
  const clientProfile = client_profile || ({} as ClientProfile)
  const { languages } = clientProfile

  const extraOptions = useMemo(() => {
    const options = languages || []

    return options
      .filter((item) => item?.language?.is_default === false)
      .map((item) => ({
        label: item.language.name,
        value: item.language.id.toString(),
      }))
  }, [languages])

  const watchSecondary = watch('secondary_language_id')

  return (
    <>
      <Title order={4}>{t('language_spoken')}</Title>
      <LanguagesGroupFields
        initialVisible={!!watchSecondary}
        extraOptions={extraOptions}
        boldTitle={boldTitle}
      />
    </>
  )
}
