import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  groupItem: {
    fontSize: '14px',
    alignItems: 'flex-start',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '14px',
    },
  },

  groupItemLabel: {
    width: '35%',
    color: theme.colors.gray[7],
    fontWeight: 600,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '14px',
    },
  },

  groupItemValue: {
    width: '60%',
    fontWeight: 400,
  },

  buttonRating: {
    width: '100%',
    paddingBottom: '16px',
    borderBottom: '1px solid lightgrey',
  },
}))
