import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBookingPermissions } from '@/features/bookings/hooks'
import { BookingModel, BookingService } from '@/features/bookings/types/models'
import { RatingEntryPopupManagerControl } from '@/features/rating/components/RatingEntryPopupManager'
import { RatingSubmittedActionMeta } from '@/features/rating/hooks/useRatingEntryPopupModule'

interface IProps {
  showAsTable: boolean
  bookingService: BookingService | BookingModel
  onRatingSubmitted: (rating: any, actionMeta: any) => void
}

export const RatingControl = ({
  showAsTable = false,
  bookingService,
  onRatingSubmitted,
}: IProps) => {
  const { t } = useTranslation()

  const { isRatingManageAllow } = useBookingPermissions()

  const { client_rating } = bookingService

  const onHandleRatingSubmitted = (rating: any, actionMeta: RatingSubmittedActionMeta) => {
    actionMeta = {
      ...actionMeta,
      payload: {
        bookingServiceId: bookingService?.id,
      },
    }
    onRatingSubmitted(rating, actionMeta)
  }

  return (
    <RatingEntryPopupManagerControl
      showAsTable={showAsTable}
      bookingId={bookingService?.id}
      rating={client_rating}
      value={client_rating?.value}
      disabled={!isRatingManageAllow(bookingService)}
      ratingControlProps={{ size: 'lg' }}
      onRatingSubmitted={onHandleRatingSubmitted}
    />
  )
}
