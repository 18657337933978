import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  CheckboxFieldController,
  SelectController,
  TextInputController,
} from '@/components/FormElements'
import { HOTEL_TYPE } from '@/const/hotel'
import { useAppState } from '@/features/app/hooks'
import { bookingAPI } from '@/features/bookings/api'
import { hotelHelper } from '@/helpers/hotel'

type HotelFormSectionValues = {
  is_hotel: boolean
  hotel_name: string
  //city_id: number | string | null
  hotel_id: number | string | null
}

interface IProps {
  disabled: boolean
}

export const HotelFormSection = ({ disabled = false }: IProps) => {
  const { t } = useTranslation()
  const [hotels, setHotels] = useState([])
  const {
    appState: { mobileView },
  } = useAppState()

  const { control, watch, setValue } = useFormContext<HotelFormSectionValues>()

  const watchIsHotel = watch('is_hotel')
  const watchHotelId = watch('hotel_id')
  //const watchCitylId = watch('city_id')

  const onHotelChange = (value: string) => {
    setValue('hotel_id', value)
  }

  useEffect(() => {
    loadHotels()
  }, [])

  /*  useEffect(() => {
    loadHotels()
  }, [watchCitylId])
*/
  const loadHotels = async () => {
    const { data } = await bookingAPI.getHotels()
    const hotelsList = data.map((item: any) => ({
      label: item.name,
      value: item.id.toString(),
    }))
    setHotels(hotelsList)
  }

  const getHotels = () => {
    return [...hotels, ...hotelHelper.general.getOtherHotelOptions()]
  }

  return (
    <>
      <CheckboxFieldController
        control={control}
        name={'is_hotel'}
        id={'is_hotel'}
        label={t('i_am_in_hotel')}
        mb={'md'}
        disabled={disabled}
        className={mobileView ? 'mt-4' : ''}
      />

      {watchIsHotel && (
        <SelectController
          control={control}
          name={'hotel_id'}
          id={'hotel_id'}
          label={t('hotel_name')}
          size={mobileView ? 'md' : 'lg'}
          placeholder={t('hotel_name')}
          mb={'md'}
          nothingFoundLabel={'hotel_no_options'}
          data={getHotels()}
          onChange={onHotelChange}
          disabled={disabled}
          searchable
          clearable
          styles={
            mobileView
              ? {
                  label: {
                    fontWeight: 'bold',
                    fontSize: '16px !important',
                  },
                }
              : {}
          }
        />
      )}
      {watchIsHotel && watchHotelId == HOTEL_TYPE.OTHER && (
        <TextInputController
          control={control}
          name={'hotel_name'}
          id={'hotel_name'}
          label={t('hotels_name')}
          placeholder={t('hotels_name')}
          size={mobileView ? 'md' : 'lg'}
          mb={'md'}
          defaultValue={''}
          disabled={disabled}
          styles={
            mobileView
              ? {
                  label: {
                    fontWeight: 'bold',
                    fontSize: '16px !important',
                  },
                }
              : {}
          }
        />
      )}
    </>
  )
}
