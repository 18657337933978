import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MegaTagTitle } from '@/components/Elements'
import { useEvents } from '@/features/app/hooks'
import { BookingConfirmedContainer, Layout } from '@/features/booking/components/BookingConfirmed'
import { checkoutState } from '@/features/booking/store/checkout.slice'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'
import { useUser } from '@/features/user'

export const Confirmed = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { booking } = useSelector((state: any) => state.booking.checkout)
  const events = useEvents()
  const { user } = useUser()
  const [justCreatedUser, setJustCreatedUser] = useState(false)

  useEffect(() => {
    if (!booking) {
      navigate('/bookings')
    }
    try {
      const total = booking.total_amount / 100
      const bookingId = booking.id
      const userHasntBookings = !user.has_bookings

      events.actions.purchaseOcasional(total.toFixed(2), bookingId, userHasntBookings)
      events.actions.purchaseEcommerce(total.toFixed(2), {
        transaction_id: bookingId,
        coupon: TAG_MANAGER_EVENT.PURCHASE_OCASIONAL,
        affiliation: TAG_MANAGER_EVENT.PURCHASE_OCASIONAL,
        total: total,
        value: total,
        currency: 'EUR',
        items: [
          {
            item_name: TAG_MANAGER_EVENT.PURCHASE_OCASIONAL,
          },
        ],
      })
    } catch (e) {
      console.error('checkout event error', e)
    }
    setJustCreatedUser(booking.just_created_user)
    dispatch(checkoutState.setBooking(null))
  }, [])

  return (
    <>
      <MegaTagTitle title={'booking_confirmed'} />

      <Layout>
        <BookingConfirmedContainer justCreatedUser={justCreatedUser} />
      </Layout>
    </>
  )
}
