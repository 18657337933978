import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonLink } from '@/components/Elements'
import { useChatConversationData } from '@/features/chat/hooks'

export const ViewProfile = () => {
  const { t } = useTranslation()

  const { getParticipant } = useChatConversationData()

  const participant = getParticipant()

  return participant ? (
    <ButtonLink to={`/babysitters/${participant?.id}`} variant={'outline'} size={'sm'}>
      {t('view_profile')}
    </ButtonLink>
  ) : null
}
