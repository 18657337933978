import React, { useCallback } from 'react'
import { ScrollArea, Space } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { ChatModel } from '@/features/chat/types/models'
import { Item } from './Item'

interface IProps {
  items: ChatModel[]
  onSelect: (data: ChatModel) => void
  participantId: number | undefined
  activeChat: ChatModel | null
}

export const ChatsList = ({ items, onSelect, participantId, activeChat }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  const isActive = useCallback(
    (chat: ChatModel) => {
      if (activeChat) {
        return chat.id === activeChat?.id
      }
      return false
    },
    [activeChat]
  )

  return (
    <ScrollArea className={'h-full'} mx="-xs" px="xs">
      {!mobileView && <Space h={'xs'} />}

      {items.map((item, i) => (
        <Item key={i} data={item} onSelect={onSelect} active={isActive(item)} />
      ))}

      <Space h={'xs'} />
    </ScrollArea>
  )
}
