import React from 'react'
import { ModalProps as MantineModalProps, Modal, ScrollArea } from '@mantine/core'
import { MobileFullModal } from '@/components/Elements/Modals/MobileFullModal'
import { useAppState } from '@/features/app/hooks'
import useStyles from './Switches.styles'

export interface ModalProps extends MantineModalProps {
  opened: boolean
  onClose: () => void
  headerBorder?: boolean
  header?: React.ReactNode
  leftIcon?: string
  rightIcon?: string
  background?: string
  title: string
  fromBooking?: boolean
  children: React.ReactNode
}

export const ModalOrDialogSwitch = ({
  opened,
  onClose,
  header,
  headerBorder = false,
  leftIcon = 'arrow',
  rightIcon = '',
  title,
  children,
  background = 'white',
  fromBooking = false,
  ...props
}: ModalProps) => {
  const { classes } = useStyles()
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <>
      {mobileView ? (
        <MobileFullModal
          header={header}
          opened={opened}
          onClose={onClose}
          headerBorder={headerBorder}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          title={title}
          fromBooking={fromBooking}
          background={background}
        >
          {children}
        </MobileFullModal>
      ) : (
        <Modal title={title} opened={opened} onClose={onClose}>
          {children}
        </Modal>
      )}
    </>
  )
}
