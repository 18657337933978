import { IconArrowLeft } from '@tabler/icons'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Text, useMantineTheme } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { MobileHeaderModal } from '@/components/Elements/Header/MobileHeader'
import { MobileFullModal } from '@/components/Elements/Modals/MobileFullModal'
import { useAppState } from '@/features/app/hooks'
import { useChatConversationData } from '@/features/chat/hooks'
import { ROLE } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'
import { usePopup } from '@/hooks'
import useStyles from './Chat.styles'
import { Room } from './Room'
import { Sidebar } from './Sidebar'

export const Chat = () => {
  const {
    appState: { mobileView },
  } = useAppState()
  const theme = useMantineTheme()
  const { classes } = useStyles()
  const navigate = useNavigate()

  const { visible, open, close } = usePopup()
  const { getParticipant } = useChatConversationData()
  const participant = getParticipant()
  const { getName } = useUsersModel()

  const openRoom = () => {
    open(true)
  }

  useEffect(() => {
    if (mobileView && participant?.role) {
      openRoom()
    }
  }, [])

  const goToProfile = () => {
    if (participant?.role === ROLE.Babysitter) navigate(`/babysitters/${participant?.id}`)
  }

  return (
    <div className={classes.root}>
      {mobileView ? (
        <>
          <Sidebar openRoom={openRoom} />

          <MobileFullModal
            opened={visible}
            leftIcon={'arrow_left'}
            onClose={close}
            headerBorder={true}
            scrollable={false}
            header={
              <div className={'flex justify-center'}>
                <MobileHeaderModal
                  border={true}
                  left={
                    <IconArrowLeft
                      size={20}
                      color={theme.colors.primary[theme.fn.primaryShade()]}
                      onClick={close}
                    />
                  }
                  right={<Text></Text>}
                  title={
                    <div className={'flex'} onClick={goToProfile}>
                      {participant?.role === ROLE.Babysitter && (
                        <div className={'mr-2'}>
                          <AvatarProvider size={32} avatar={participant?.avatar} />
                        </div>
                      )}
                      <Text size={16} weight={600} className="mt-1">
                        {getName(participant)}
                      </Text>
                    </div>
                  }
                />
              </div>
            }
          >
            <Room />
          </MobileFullModal>
        </>
      ) : (
        <>
          <Sidebar />

          <Room />
        </>
      )}
    </div>
  )
}
