import React from 'react'
import { useAppSelector } from '@/store'

export const useChatConversationData = () => {
  const { data, backgroundUnreadMessages } = useAppSelector((state) => state.chat.conversation.data)

  const getParticipant = () => data?.participant

  return {
    conversation: data,
    backgroundUnreadMessages,
    getParticipant,
  }
}
