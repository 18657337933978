import { yupResolver } from '@hookform/resolvers/yup'
import { IconEdit } from '@tabler/icons'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group, Button as MantineButton, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { ModalOrDialogSwitch } from '@/components/Elements/MobileWebSwitchs/ModalOrDialogSwitch'
import { TextInputController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { calendarsAPI } from '@/features/calendar/api'
import useStyles from './WalletInfo.styles'
import { validationSchema } from './validation'

type InfoItem = {
  label: string
  value: React.ReactNode
}

export const WalletInfo = () => {
  const { t } = useTranslation()

  const theme = useMantineTheme()

  const { classes } = useStyles()
  const [paymentData, setPaymentData] = useState<InfoItem[]>([])
  const [info, setInfo] = useState<any>([])
  const [bankInfo, setBankInfo] = useState<any>([])
  const [visible, setVisible] = useState(false)
  const [defaultValues, setDefaultValues] = useState({
    account_number: '',
    bank: '',
    swift: '',
  })

  const {
    appState: { mobileView },
  } = useAppState()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const getWalletInfo = async (params?: any) => {
    try {
      const { data } = await calendarsAPI.getWalletInfo()
      setInfo(data)
      if (data[0]) {
        setValue('account_number', data[0].account_number)
        setValue('bank', data[0].bank)
        setValue('swift', data[0].swift)

        setPaymentData([
          {
            label: 'IBAN',
            value: data[0].account_number,
          },
          {
            label: 'SWIFT',
            value: data[0].swift,
          },
          {
            label: t('bank_name'),
            value: data[0].bank,
          },
        ])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getWalletInfo()
  }, [])

  const onSubmit = async (formData: any) => {
    try {
      const { data } = await calendarsAPI.saveBankInfo(formData)
      getWalletInfo()
    } catch (error) {
    } finally {
      setVisible(false)
    }
  }

  const renderGroup = ({ label, value }: InfoItem) => (
    <Group className={classes.groupItem}>
      <Text className={classes.groupItemLabel}>{label}</Text>
      <Text className={classes.groupItemValue}>{value ? value : '-'}</Text>
    </Group>
  )

  return (
    <div className={mobileView ? 'flex flex-col gap-2' : 'flex flex-col items-end gap-4'}>
      {mobileView ? (
        <>
          <div className={'flex w-100'}>
            <div className={'w-10/12'}>
              <Title order={5}>{t('payment_details')}</Title>
            </div>
            <div className={'flex justify-end w-2/12'}>
              <IconEdit
                color={theme.colors.primary[theme.fn.primaryShade()]}
                stroke={1}
                onClick={() => setVisible(true)}
              />
            </div>
          </div>
          <Stack className={'w-100 gap-2.5'}>
            {paymentData.map((item: InfoItem, i: number) => (
              <React.Fragment key={i}>{renderGroup(item)}</React.Fragment>
            ))}
          </Stack>
        </>
      ) : (
        <>
          <Button onClick={() => setVisible(true)} severity="danger">
            <div className="flex gap-2">
              <i className="pi pi-user-edit"></i>
              <span>{t('edit_bank_information')}</span>
            </div>
          </Button>
          <Card
            className="p-0 w-full"
            pt={{
              body: { className: 'p-0' },
              content: { className: 'p-0' },
            }}
          >
            <DataTable
              value={info}
              dataKey="id"
              tableStyle={{ minWidth: '60rem' }}
              emptyMessage="Bank information not set yet"
            >
              <Column field="account_number" header="IBAN" />
              <Column field="swift" header="SWIFT" />
              <Column field="bank" header={t('bank_name')} />
            </DataTable>
          </Card>
        </>
      )}
      <ModalOrDialogSwitch
        title={t('edit_bank_account_details')}
        opened={visible}
        headerBorder={true}
        onClose={() => setVisible(false)}
      >
        <form className="flex flex-col gap-4 m-0 p-4" onSubmit={handleSubmit(onSubmit)}>
          <TextInputController
            control={control}
            name={'account_number'}
            id={'account_number'}
            label={t('bank_account_number')}
            placeholder={t('bank_account_number')}
            size={mobileView ? 'md' : 'lg'}
            mb={mobileView ? '0' : 'md'}
          />

          <TextInputController
            control={control}
            name={'swift'}
            id={'swift'}
            label={t('swift')}
            placeholder={t('swift')}
            size={mobileView ? 'md' : 'lg'}
            mb={mobileView ? '0' : 'md'}
          />

          <TextInputController
            control={control}
            name={'bank'}
            id={'bank'}
            label={t('bank_name')}
            placeholder={t('bank_name')}
            size={mobileView ? 'md' : 'lg'}
            mb={mobileView ? '0' : 'md'}
          />

          {mobileView ? (
            <div className="flex w-100">
              <MantineButton className={'w-100'} type="submit">
                {t('save')}
              </MantineButton>
            </div>
          ) : (
            <div className="flex justify-end gap-4">
              <MantineButton
                variant="default"
                color="dark"
                type="button"
                onClick={() => setVisible(false)}
              >
                {t('cancel')}
              </MantineButton>
              <MantineButton type="submit">{t('save')}</MantineButton>
            </div>
          )}
        </form>
      </ModalOrDialogSwitch>
    </div>
  )
}
