import classNames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollArea } from '@mantine/core'
import { NavTabs } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { useBookingData, useBookingPermissions } from '@/features/bookings/hooks'
import { BookingModel } from '@/features/bookings/types/models'
import { useUser } from '@/features/user'
import { BookingDetailsContent } from '../../BookingDetailsContent'
import { BookingInvoicesContainer } from '../../BookingInvoicesContainer'
import { BookingScheduleContainer } from '../../BookingScheduleContainer'
import { BookingTrackerContainer } from '../../BookingTrackerContainer'

const TABS_KEY = {
  DETAILS: 'details',
  SCHEDULE: 'schedule',
  TRACKER: 'tracker',
  INVOICES: 'invoices',
}

interface IProps {
  ratingProps?: any
}
export const TabsLayoutContent = ({ ratingProps }: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const { booking } = useBookingData()
  const { isTimeTrackerAllow } = useBookingPermissions()

  const { isClient } = useUser()

  const { type, status, timetable } = booking as BookingModel

  const tabs = [
    {
      label: t('details'),
      value: TABS_KEY.DETAILS,
    },
    {
      label: t('schedule'),
      value: TABS_KEY.SCHEDULE,
      hidden: type === BOOKING_TYPE.ONETIME || type === BOOKING_TYPE.ANIMATION /* &&
        (!timetable.onetime_ranges || timetable.onetime_ranges.length == 0)*/,
    },
    {
      label: t('time_tracker'),
      value: TABS_KEY.TRACKER,
      hidden: !isTimeTrackerAllow(status),
    },
    {
      label: t('invoices'),
      value: TABS_KEY.INVOICES,
      hidden: !isClient(),
    },
  ]

  const [tab, setTab] = useState(TABS_KEY.DETAILS)

  const onTabChange = (value: string) => {
    setTab(value)
  }

  return (
    <>
      <NavTabs tabs={tabs.filter((tab) => !tab.hidden)} value={tab} onChange={onTabChange} />

      {mobileView ? (
        <div
          className={classNames(mobileView ? 'px-2 lg:px-0' : 'h-full px-2 lg:px-0 overflow-auto', {
            hidden: tab !== TABS_KEY.DETAILS,
          })}
        >
          <div className={'px-3 pt-2 w-full h-full'}>
            <BookingDetailsContent ratingProps={ratingProps} />
          </div>
        </div>
      ) : (
        <ScrollArea
          offsetScrollbars
          className={classNames('flex grow', {
            hidden: tab !== TABS_KEY.DETAILS,
          })}
        >
          <div className={'px-4 pt-4'}>
            <BookingDetailsContent />
          </div>
        </ScrollArea>
      )}

      {tab === TABS_KEY.SCHEDULE && (
        <div className={classNames('h-full lg:px-0 overflow-auto lg:overflow-hidden')}>
          <div className={mobileView ? 'px-3 pt-2 w-full h-full' : 'px-4 pt-4 w-full h-full'}>
            <BookingScheduleContainer />
          </div>
        </div>
      )}

      {tab === TABS_KEY.TRACKER && (
        <div className={classNames('h-full overflow-auto')}>
          <div className={mobileView ? 'px-3 pt-2 w-full h-full' : 'px-4 pt-4 w-full h-full'}>
            <BookingTrackerContainer />
          </div>
        </div>
      )}

      {tab === TABS_KEY.INVOICES && (
        <div className={classNames('h-full overflow-auto')}>
          <div className={mobileView ? 'px-3 pt-2 w-full h-full' : 'px-4 pt-4 w-full h-full'}>
            <BookingInvoicesContainer />
          </div>
        </div>
      )}
    </>
  )
}
