import React from 'react'
import { ScrollArea } from '@mantine/core'
import { Book } from '@/features/book/routes/book'
import { BackLink } from '@/features/header/layout/BackLink'
import { BookingDesktopHeader } from '@/features/header/layout/Header'
import useStyles from './BookRootNoLoginDesktop.styles'

export const BookRootNoLoginDesktop = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.section}>
      <div className={classes.infoSection}>
        <BookingDesktopHeader />
        <ScrollArea className={classes.stepperWap}></ScrollArea>
        <BackLink />
      </div>
      <div id="contentXPTO" className={classes.contentSection}>
        <Book />
      </div>
    </div>
  )
}
