import React from 'react'
import { BabysitterRatingModel, ClientRatingModel } from '@/features/rating/types/models'
import { RatingEntryModal } from '../RatingEntryModal'
import { RatingEntryEditContainer } from './RatingEntryEditContainer'

interface IProps {
  rating: ClientRatingModel | BabysitterRatingModel
  visible: boolean
  onClose: () => void
  onDestroy: () => void
}

export const RatingEntryEdit = ({ rating, visible, onClose, onDestroy }: IProps) => {
  return (
    <>
      <RatingEntryModal visible={visible} onClose={onClose}>
        <RatingEntryEditContainer rating={rating} onClose={onClose} onDestroy={onDestroy} />
      </RatingEntryModal>
    </>
  )
}
