import React from 'react'
import { Text } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { useChatConversationData } from '@/features/chat/hooks'
import { useUser } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'
import useStyles from './Info.styles'

export const Info = () => {
  const { classes } = useStyles()

  const { getParticipant } = useChatConversationData()

  const { getName } = useUsersModel()

  const { isClient } = useUser()

  const participant = getParticipant()

  const config = {
    avatar: isClient(),
  }

  return (
    <div className={'flex flex-wrap gap-4 items-center'}>
      {config.avatar && (
        <div className={classes.avatar}>
          <AvatarProvider size={40} avatar={participant ? participant?.avatar : null} />
        </div>
      )}

      <div>
        <Text weight={700} className={classes.name}>
          {getName(participant)}
        </Text>
      </div>
    </div>
  )
}
