import React from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from '@mantine/core'
import { PerfectScrollbar } from '@/components/Elements'
import { MessageType } from '@/features/chat/types/models'
import { ScrollbarUtils } from '@/utils'
import { LoadMore } from './LoadMore'
import { Loading } from './Loading'
import useStyles from './MessagesList.styles'
import { NoData } from './NoData'

interface IProps {
  scrollRef: any
  items: MessageType[]
  loading: boolean
  isMoreLoading: boolean
  onLoadMore: () => void
  renderMessages: () => any
  hasMore?: boolean
  lastMessageRef: any
}

export const MessagesList = (props: IProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const onScroll = (e: any) => {
    if (ScrollbarUtils.isCloseToTop(e, 300)) {
      props.onLoadMore()
    }
  }

  const hasMore = () => {
    if (props.hasMore && !props.isMoreLoading) {
      return <LoadMore onLoad={props.onLoadMore} />
    }
  }

  return (
    <PerfectScrollbar
      className={classes.root}
      containerRef={(ref) => (props.scrollRef.current = ref)}
      onScrollY={onScroll}
    >
      {props.loading ? (
        <Loading />
      ) : (
        <>
          {props.isMoreLoading && (
            <div className={'text-center mt-2 mb-4'}>
              <Loader size={'sm'} />
            </div>
          )}

          {hasMore()}

          {props.items.length ? <div>{props.renderMessages()}</div> : <NoData />}
          <div ref={props.lastMessageRef}></div>
        </>
      )}
    </PerfectScrollbar>
  )
}
