import React from 'react'
import { ChatNavControl } from '@/features/chat/components/Elements'
import { ChatModel } from '@/features/chat/types/models'
import { BabysitterUser } from '@/features/user'

interface IProps {
  babysitter: BabysitterUser
  chat: ChatModel
}

export const ChatControl = ({ babysitter, chat }: IProps) => {
  const { id, avatar, first_name, last_name, role } = babysitter

  //console.log('ChatControl', babysitter, chat)

  return <ChatNavControl chat={chat} participant={{ id, avatar, first_name, last_name, role }} />
}
