import React from 'react'
import { BOOKING_STATUS, BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { BookingModel, BookingService } from '@/features/bookings/types/models'
import { useUser } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'

export const useBookingPermissions = () => {
  const { isBabysitter, isClient } = useUser()

  const { isUserActive } = useUsersModel()

  const isCancelAllow = (status: BOOKING_STATUS) => {
    let forbidden = [
      BOOKING_STATUS.CANCELED_BABYSITTER,
      BOOKING_STATUS.CANCELED_CLIENT,
      BOOKING_STATUS.CANCELED_LATE_PAYMENT,
      BOOKING_STATUS.FINISHED,
    ]

    if (isBabysitter()) {
      forbidden = [...forbidden, BOOKING_STATUS.WAITING_BABYSITTER, BOOKING_STATUS.REQUEST_FINISH]
    }

    return !!status && !forbidden.includes(status)
  }

  const isStatusShowAllow = (status: BOOKING_STATUS) => {
    if (isBabysitter()) {
      const allowStatuses = [
        BOOKING_STATUS.CANCELED_BABYSITTER,
        BOOKING_STATUS.CANCELED_CLIENT,
        BOOKING_STATUS.REQUEST_FINISH,
        BOOKING_STATUS.FINISHED,
      ]

      return allowStatuses.includes(status)
    }

    return true
  }

  const isRequestFinishAllow = (status: BOOKING_STATUS) => {
    if (isBabysitter()) {
      const allowStatuses = [BOOKING_STATUS.UPCOMING]

      return allowStatuses.includes(status)
    }

    return false
  }

  const isFinishAllow = (status: BOOKING_STATUS) => {
    if (isClient()) {
      const allowStatuses = [BOOKING_STATUS.REQUEST_FINISH]

      return allowStatuses.includes(status)
    }

    return false
  }

  const isTimeTrackerAllow = (status: BOOKING_STATUS) => {
    if (isBabysitter()) {
      const forbidden = [BOOKING_STATUS.WAITING_BABYSITTER]
      return !forbidden.includes(status)
    }

    return true
  }

  const isPayAllow = (booking: BookingModel) => {
    if (isClient()) {
      const { has_unpaid_invoice } = booking

      return has_unpaid_invoice
    }

    return false
  }

  const isRatingManageAllow = (booking: BookingModel | BookingService) => {
    const { user } = booking

    if (isClient()) {
      const { babysitter } = booking as BookingModel
      return !!isUserActive(babysitter)
    } else if (isBabysitter()) {
      return !!isUserActive(user)
    }

    return false
  }

  const isRatingAllow = (booking: BookingModel | BookingService) => {
    const allowStatuses = [BOOKING_STATUS.FINISHED]

    const { status, client_rating } = booking

    if (allowStatuses.includes(status)) {
      const { babysitter_rating } = booking as BookingModel
      const rating = isBabysitter() ? client_rating : babysitter_rating

      return !!rating || isRatingManageAllow(booking)
    }

    return false
  }

  const isNextPaymentShowAllow = (booking: BookingModel) => {
    if (isClient()) {
      const { type } = booking

      return type !== BOOKING_TYPE.ONETIME && type !== BOOKING_TYPE.ANIMATION
    }

    return false
  }

  return {
    isCancelAllow,
    isStatusShowAllow,
    isRequestFinishAllow,
    isFinishAllow,
    isTimeTrackerAllow,
    isPayAllow,
    isRatingAllow,
    isRatingManageAllow,
    isNextPaymentShowAllow,
  }
}
