import React from 'react'
import useStyles from './ActionBar.styles'
import { ViewProfile } from './Controls'

const defaultConfig = {
  viewProfile: false,
}

interface IProps {
  config: {
    viewProfile: boolean
  }
}

export const ActionBar = ({ config }: IProps) => {
  const { classes } = useStyles()

  config = {
    ...defaultConfig,
    ...config,
  }

  return <div className={'flex flex-wrap gap-4'}>{config?.viewProfile && <ViewProfile />}</div>
}
