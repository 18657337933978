import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Stack, Text } from '@mantine/core'
import { Button } from '@/components/Elements'
import { RatingControl } from '@/features/booking-service/components/Services/Cards/GeneralServiceCard/Footer/Controls'
import { RatingButtonControl } from '@/features/rating/components/Elements'
import { RatingEntryPopupManager } from '@/features/rating/components/RatingEntryPopupManager'
import {
  RatingEntryPopupManagerProvider,
  RatingEntryPopupModuleProvider,
} from '@/features/rating/contexts'
import { useRatingEntryPopupManager } from '@/features/rating/hooks'
import { useRatingEntryPopupModule } from '@/features/rating/hooks/useRatingEntryPopupModule'
import { BabysitterRatingModel, ClientRatingModel } from '@/features/rating/types/models'
import useStyles from './RatingEntryPopupManagerControl.styles'

interface IProps {
  value?: number
  disabled?: boolean
  ratingControlProps?: any
  onRatingSubmitted: (rating: any | null, actionMeta: any) => void
  rating?: ClientRatingModel | BabysitterRatingModel
  bookingId?: number
  showAsTable?: boolean
}

export const RatingEntryPopupManagerControl = ({
  showAsTable = false,
  value,
  disabled,
  ratingControlProps,
  onRatingSubmitted,
  rating,
  bookingId,
}: IProps) => {
  const ratingPopupManager = useRatingEntryPopupManager({})
  const { classes } = useStyles()
  const { t } = useTranslation()

  const onClick = () => {
    if (value) {
      ratingPopupManager.navigation.onScreenEdit({ rating })
    } else {
      ratingPopupManager.navigation.onScreenCreate({ bookingId })
    }
  }

  const onHandleRatingSubmitted = (rating: any, actionMeta: any) => {
    ratingPopupManager.navigation.onScreenClose()

    onRatingSubmitted(rating, actionMeta)
  }

  const ratingPopupModule = useRatingEntryPopupModule({
    onRatingSubmitted: onHandleRatingSubmitted,
  })

  return (
    <>
      {showAsTable ? (
        <Stack className={'my-3 gap-2.5 w-100'}>
          <Group className={classes.groupItem}>
            <Text className={classes.groupItemLabel}>{t('rating')}</Text>
            <div className={classes.groupItemValue}>
              <RatingButtonControl
                value={value}
                ratingProps={ratingControlProps}
                disabled={disabled}
                onClick={onClick}
              />
            </div>
          </Group>
          {!value && !disabled && (
            <div className={classes.buttonRating}>
              <Button fullWidth onClick={onClick}>
                {t('rate_service')}
              </Button>
            </div>
          )}
        </Stack>
      ) : (
        <RatingButtonControl
          value={value}
          ratingProps={ratingControlProps}
          disabled={disabled}
          onClick={onClick}
        />
      )}

      <RatingEntryPopupManagerProvider {...ratingPopupManager}>
        <RatingEntryPopupModuleProvider {...ratingPopupModule}>
          <RatingEntryPopupManager />
        </RatingEntryPopupModuleProvider>
      </RatingEntryPopupManagerProvider>
    </>
  )
}
