import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ChatModel } from '@/features/chat/types/models'
import useStyles from './Body.styles'
import { CountBadge } from './CountBadge'
import { Date } from './Date'
import { Message } from './Message'
import { Name } from './Name'

interface IProps {
  data: ChatModel
}

export const Body = ({ data }: IProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { last_message, participant, unread_count, booking } = data

  const [hour, minute] = booking?.time_start?.split(':') ?? [0, 0]

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className={'flex flex-col grow min-w-0'}>
          {/*isBabysitter() &&
            (booking ? <Booking booking={booking.address} /> : <Name participant={participant} />)}
          {isClient() && <Name participant={participant} />}
          {isBabysitter() && (
            <span className="text-sm text-gray-900">
              {booking_owner?.first_name} {booking_owner?.last_name}
            </span>
          )*/}
          <Name participant={participant} />
          {booking && (
            <span className="text-xs text-gray-500">
              {t('service_date')}:{' '}
              {moment(booking?.date_start).hour(hour).minute(minute).format('DD/MM/YYYY')}
            </span>
          )}
        </div>

        <div className={'ml-1'}>
          <Date value={last_message?.created_at} />
        </div>
      </div>

      <div className={classes.subBar}>
        <div className={'flex grow min-w-0'}>
          <Message message={last_message} />
        </div>

        {unread_count > 0 && (
          <div className={'ml-1'}>
            <CountBadge>{unread_count}</CountBadge>
          </div>
        )}
      </div>
    </div>
  )
}
