import axios from 'axios'
import { SubmitBabysitterRatingDTO, SubmitClientRatingDTO } from '@/features/rating/api/dto'
import { GetBookingDTO, SubmitBookingTrackerDTO } from './dto'

class BookingAPI {
  getBooking = async (id: number, params: GetBookingDTO) => {
    return axios.get(`api/bookings/${id}`, {
      params,
    })
  }

  getDays = async (id: number) => {
    return axios.get(`api/bookings/${id}/days`)
  }

  cancelBooking = async (id: number) => {
    return axios.post(`api/bookings/${id}/cancel`)
  }

  babysitterCancelBooking = async (id: number) => {
    return axios.post(`api/bookings/${id}/babysitter-cancel`)
  }

  requestFinishBooking = async (id: number) => {
    return axios.post(`api/bookings/${id}/request-finish`)
  }

  finishBooking = async (id: number) => {
    return axios.post(`api/bookings/${id}/finish`)
  }

  getTrackers = async (id: number, params: any) => {
    return axios.get(`api/bookings/${id}/trackers`, {
      params,
    })
  }

  createTracker = async (id: number, data: SubmitBookingTrackerDTO) => {
    return axios.post(`api/bookings/${id}/trackers`, data)
  }

  createBabysitterRating = async (id: number, data: SubmitBabysitterRatingDTO) => {
    return axios.post(`api/bookings/${id}/babysitter-ratings`, data)
  }

  createClientRating = async (id: number, data: SubmitClientRatingDTO) => {
    return axios.post(`api/bookings/${id}/client-ratings`, data)
  }

  getInvoices = async (id: number, params: any) => {
    return axios.get(`api/bookings/${id}/invoices`, {
      params,
    })
  }

  getCalculatedPrice = async (isLoggedIn: boolean, params: any) => {
    if (isLoggedIn) return axios.post(`api/bookings/calculate-price-auth`, params)
    else return axios.post(`api/bookings/calculate-price`, params)
  }

  getHotels = async () => {
    return axios.get('api/bookings/v2/hotels-select')
    //return { data: result }
  }
}

export const bookingAPI = new BookingAPI()
