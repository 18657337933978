import React, { createContext, useContext } from 'react'
import { BabysitterRatingModel, ClientRatingModel } from '@/features/rating/types/models'

const RatingEntryPopupModuleContext = createContext({
  onRatingSubmitted: (
    rating: BabysitterRatingModel | ClientRatingModel | null,
    actionMeta: any
  ) => {},
})

interface IProps {
  children: React.ReactNode
  onRatingSubmitted: (
    rating: BabysitterRatingModel | ClientRatingModel | null,
    actionMeta: any
  ) => void

  [x: string]: any
}

const RatingEntryPopupModuleProvider = ({ children, ...props }: IProps) => {
  return (
    <RatingEntryPopupModuleContext.Provider value={{ ...props }}>
      {children}
    </RatingEntryPopupModuleContext.Provider>
  )
}

const useRatingEntryPopupModuleContext = () => useContext(RatingEntryPopupModuleContext)

export { RatingEntryPopupModuleProvider, useRatingEntryPopupModuleContext }
