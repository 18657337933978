import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useRatingEntryPopupModuleContext } from '@/features/rating/contexts'
import { ratingBabysitterEditSubmitPA, ratingClientEditSubmitPA } from '@/features/rating/store'
import { BabysitterRatingModel, ClientRatingModel } from '@/features/rating/types/models'
import { useUser } from '@/features/user'
import { useNotify } from '@/hooks'
import { RatingEntryForm } from '../../RatingEntryForm'

interface IProps {
  rating: ClientRatingModel | BabysitterRatingModel
  onClose: () => void
  onDestroy: () => void
}

export const RatingEntryEditContainer = ({ rating, onClose, onDestroy }: IProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => onDestroy()
  }, [])

  const { onRatingSubmitted } = useRatingEntryPopupModuleContext()

  const { user, isBabysitter } = useUser()

  const onSuccess = (data: any) => {
    onRatingSubmitted && onRatingSubmitted(data, { action: 'edit', entity: user?.role })
  }

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }

    const action = isBabysitter() ? ratingClientEditSubmitPA : ratingBabysitterEditSubmitPA

    const data = await dispatch(action.request({ id: rating?.id, params }))
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess(data)
  }

  return (
    <>
      <RatingEntryForm
        initialValues={{
          value: rating?.value,
          person: rating?.person || 0,
          platform: rating?.platform || 0,
          same_babysitter: rating?.same_babysitter || false,
          other_feedback: rating?.other_feedback || '',
          babysitter_feedback: rating?.babysitter_feedback || '',
        }}
        onSubmit={onSubmit}
        onCancel={onClose}
        isEdit
        rating={rating}
      />
    </>
  )
}
