import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Center } from '@mantine/core'
import { Button, EmptyDataContentPlaceholder } from '@/components/Elements'
import { useEvents } from '@/features/app/hooks'

export const EmptyData = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const events = useEvents()

  const handleNew = () => {
    events.actions.newBooking()
    navigate('/book-now')
  }

  return (
    <Center className={'h-full'}>
      <EmptyDataContentPlaceholder
        title={t('no_pending_interview')}
        message={t('bookings.available.empty.message')}
        action={
          <Button color={'secondary'} onClick={handleNew}>
            {t('new_booking')}
          </Button>
        }
      />
    </Center>
  )
}
