import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { FormTypes } from '@/types'
import { authAPI } from '../../api'
import { authData } from '../data/slice'
import { AUTH_LOGOUT_FAILED, AUTH_LOGOUT_REQUEST, AUTH_LOGOUT_SUCCESS } from './actionTypes'

export const authLogoutPA = createPromiseAction(
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILED
)<undefined, any, AxiosError<FormTypes.ValidationErrors>>()

function* logout(action: PromiseAction<string, undefined, any>) {
  try {
    const response: AxiosResponse = yield call(authAPI.logOut)
    const { data } = response

    yield put(authLogoutPA.success({}))

    yield put(authData.cleanData())

    resolvePromiseAction(action, {})
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* logoutSaga() {
  yield takeEvery(authLogoutPA.request, logout)
}
