import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMixPanel } from '@/features/app/hooks'
import { authLogoutPA } from '@/features/auth/store'
import { userData } from '@/features/user/store'
import { useAsyncDispatch, useNotify } from '@/hooks'

export const useLogout = () => {
  const { t } = useTranslation()

  const { loading, onAsyncDispatch } = useAsyncDispatch()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { showNotification } = useNotify()
  const mixPanel = useMixPanel()

  const logout = async () => {
    try {
      mixPanel.actions.reset()
      await onAsyncDispatch(authLogoutPA.request)
      dispatch(userData.setData({}))

      navigate('/auth/client')
      setTimeout(() => {
        window.location.reload()
      }, 150)
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    logout,
    loading,
  }
}
