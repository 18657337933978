import React from 'react'

interface IProps {
  color: string
  size?: number
  className?: string
}

export const Heart = ({ size = 24, color, ...props }: IProps) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 10C1.75 6.54824 4.54822 3.75 8 3.75C9.76772 3.75 11.3639 4.48438 12.5 5.66271C13.6361 4.48438 15.2323 3.75 17 3.75C20.4518 3.75 23.25 6.54824 23.25 10C23.25 13.0381 21.4668 15.8433 19.3454 17.9856C17.2168 20.1353 14.6191 21.7492 12.7365 22.3748C12.583 22.4259 12.417 22.4259 12.2635 22.3748C10.3809 21.7492 7.78321 20.1353 5.65457 17.9856C3.53322 15.8433 1.75 13.0381 1.75 10Z"
      fill={color}
    />
  </svg>
)
