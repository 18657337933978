import React from 'react'
import { BookingService } from '@/features/bookings/types/models'
import { RatingControl } from './Controls'

interface IProps {
  config: {
    rating: boolean
  }
  bookingService: BookingService
  ratingControlProps?: any
}

const defaultConfig = {
  rating: false,
}

export const Footer = ({ config, bookingService, ratingControlProps }: IProps) => {
  config = {
    ...defaultConfig,
    ...config,
  }

  return (
    <div className={'flex flex-wrap gap-3 grow'}>
      {config?.rating && <RatingControl bookingService={bookingService} {...ratingControlProps} />}
    </div>
  )
}
