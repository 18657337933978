import React from 'react'
import { BasicLayout } from '@/components/Layouts'
import { Confirmed } from '@/features/booking/routes/confirmed'
import { New } from '@/features/booking/routes/new'
import { RegularConfirmed } from '@/features/booking/routes/regularConfirmed'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'

//config
export const getPublicRoutes = () => {
  return [
    {
      path: '/*',
      element: <BasicLayout lang={true} />,
      children: [],
    },
    {
      path: '/booking/onetime/new',
      element: (
        <BasicLayout header={false} loadSelects goToBookNow={true}>
          <New bookingType={BOOKING_TYPE.ONETIME} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/regular/new',
      element: (
        <BasicLayout header={false} loadSelects goToBookNow={true}>
          <New bookingType={BOOKING_TYPE.REGULAR} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/animation/new',
      element: (
        <BasicLayout header={false} loadSelects goToBookNow={true}>
          <New bookingType={BOOKING_TYPE.ANIMATION} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/nanny/new',
      element: (
        <BasicLayout header={false} loadSelects goToBookNow={true}>
          <New bookingType={BOOKING_TYPE.PERMANENT_NANNY} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/new',
      element: (
        <BasicLayout header={false} loadSelects goToBookNow={true}>
          <New bookingType={BOOKING_TYPE.ONETIME} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/confirmed',
      element: (
        <BasicLayout header={false} loadSelects>
          <Confirmed />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/regular/confirmed',
      element: (
        <BasicLayout header={false} loadSelects>
          <RegularConfirmed />
        </BasicLayout>
      ),
      children: [],
    },
    /*{
      path: '/booking/checkout',
      element: (
        <BasicLayout header={false} loadSelects>
          <Checkout />
        </BasicLayout>
      ),
      children: [],
    },*/
  ]
}
