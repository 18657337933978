import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  wrap: {
    display: 'flex',
    minHeight: '100vh',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      flexDirection: 'column',
    },
  },

  contentSection: {
    width: '50%',
    borderRadius: '32px',
    backgroundColor: theme.colors.mint[0],
    position: 'relative',
    right: '-65px',
    padding: '65px 65px 65px 0',
    zIndex: 1,

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: '100%',
      right: 0,
      padding: '0px',
      paddingTop: '16px',
    },
  },

  infoSection: {
    width: '50%',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: '100%',
      height: '600px',
    },
  },
}))
