import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { babysitterRatingAPI } from '@/features/rating/api'
import { SubmitBabysitterRatingDTO } from '@/features/rating/api/dto'
import {
  RATING_BABYSITTER_EDIT_SUBMIT_FAILED,
  RATING_BABYSITTER_EDIT_SUBMIT_REQUEST,
  RATING_BABYSITTER_EDIT_SUBMIT_SUCCESS,
} from './actionTypes'

type ArgsType = {
  id: number
  params: SubmitBabysitterRatingDTO
}

export const ratingBabysitterEditSubmitPA = createPromiseAction(
  RATING_BABYSITTER_EDIT_SUBMIT_REQUEST,
  RATING_BABYSITTER_EDIT_SUBMIT_SUCCESS,
  RATING_BABYSITTER_EDIT_SUBMIT_FAILED
)<ArgsType, unknown, AxiosError<any>>()

function* submit(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { id, params } = action.payload

    const response: AxiosResponse = yield call(babysitterRatingAPI.updateRating, id, params)
    const { data } = response

    yield put(ratingBabysitterEditSubmitPA.success({}))

    resolvePromiseAction(action, { ...data.data })
  } catch (err) {
    const error = err as AxiosError<any>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* submitSaga() {
  yield takeEvery(ratingBabysitterEditSubmitPA.request, submit)
}
