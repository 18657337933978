import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { bookingAPI } from '@/features/bookings/api'
import { GetBookingDTO } from '@/features/bookings/api/dto'
import { ROLE, User } from '@/features/user'
import { selectUserData } from '@/features/user/store'
import i18n from '@/i18n'
import { RootState } from '@/store'
import { RequestUtils } from '@/utils'
import { bookingsData } from '../data/slice'

const userDataState = (state: RootState) => selectUserData(state)

function* getData({
  payload: { id, params = {} },
}: PayloadAction<{ id: number; params: GetBookingDTO }>) {
  try {
    const user: User = yield select(userDataState)

    let includes = [
      'user',
      'babysitter',
      'city',
      'country',
      'primaryLanguage',
      'secondaryLanguage',
      'bookingChildren',
      // 'bookingCandidates',
      'myBookingCandidate',
    ]

    if (user.role === ROLE.Client) {
      includes = [...includes, 'bookingOpenStripeInvoice', 'babysitterRating']
    } else if (user.role === ROLE.Babysitter) {
      includes = [...includes, 'clientRating']
    }

    let requestParams: any = {
      includes,
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response: AxiosResponse = yield call(bookingAPI.getBooking, id, requestParams)

    const { data } = response

    yield put(bookingsData.getDataSuccess(data))
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(bookingsData.getDataError(message))
  }
}

function* watchGetData() {
  yield takeEvery(bookingsData.getData, getData)
}

export function* dataSaga() {
  yield all([fork(watchGetData)])
}
