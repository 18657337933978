import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Anchor, Space, Title } from '@mantine/core'
import { Alert } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { ROLE } from '@/features/user'
import { useLang } from '@/hooks'
import { authLoginPA, authRegisterPA } from '../../../store'
import useStyles from './RegisterContainer.styles'
import { FormValues, RegisterForm } from './RegisterForm'

interface IProps {
  role: ROLE
}

export const RegisterContainer = ({ role }: IProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const navigate = useNavigate()
  const {
    appState: { mobileView },
  } = useAppState()

  const { lang } = useLang()

  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: FormValues) => {
    setSuccess(false)
    const params = {
      ...values,
      role,
      lang,
    }
    const data = await dispatch(authRegisterPA.request(params))

    setSuccess(true)
    if (data && data.token) {
      //console.log('login user')
      await dispatch(authLoginPA.request({ email: values.email, token: data?.token }))
    }
  }

  const goToLogin = () => {
    console.log('send app_page_view register, role:' + role)

    navigate(role === ROLE.Client ? '/login/client' : '/login/babysitter')
  }

  return (
    <div>
      <div className={classes.wrap}>
        <Title>
          {role === ROLE.Client ? t('create_client_account') : t('create_babysitter_account')}
        </Title>

        <div className={'mt-4 mb-4 flex'}>
          <Title order={mobileView ? 5 : 6}>
            {t('already_have_account')}?{' '}
            <Anchor onClick={goToLogin}>{t('login.access_here.text')}</Anchor>
          </Title>
        </div>

        {!mobileView && success && (
          <>
            <Space h={'md'} />
            <Alert type={'success'} mb={'sm'}>
              {t('confirmation-email-sent')}!
            </Alert>
          </>
        )}
      </div>
      <RegisterForm onSubmit={handleSubmit} role={role} success={success} />
    </div>
  )
}
