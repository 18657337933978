import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MegaTagTitle } from '@/components/Elements'
import {
  BookingRegularConfirmedContainer,
  Layout,
} from '@/features/booking/components/BookingRegularConfirmed'
import { checkoutState } from '@/features/booking/store/checkout.slice'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'

export const RegularConfirmed = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { booking } = useSelector((state: any) => state.booking.checkout)
  const [justCreatedUser, setJustCreatedUser] = useState(false)
  const [type, setType] = useState(BOOKING_TYPE.REGULAR)

  useEffect(() => {
    if (!booking) {
      navigate('/bookings')
    }
    setType(booking.type)
    setJustCreatedUser(booking.just_created_user)
    dispatch(checkoutState.setBooking(null))
  }, [])

  return (
    <>
      <MegaTagTitle title={'booking_confirmed'} />

      <Layout>
        <BookingRegularConfirmedContainer justCreatedUser={justCreatedUser} type={type} />
      </Layout>
    </>
  )
}
