import { APP_NAME } from '@/const/core'

export const setDocumentTitle = (title: string) => {
  document.title = `${title} | ${APP_NAME}`
}

export const downloadFile = (data: string, name: string) => {
  const blob = new Blob([data])

  const link = document.createElement('a')

  // Set the download attribute and href for the link
  link.href = window.URL.createObjectURL(blob)
  link.download = name // Specify the filename

  // Append the link to the document body
  document.body.appendChild(link)

  // Trigger the link to start the download
  link.click()

  // Remove the link from the document body
  document.body.removeChild(link)
}
