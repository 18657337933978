import axios from 'axios'

class CalendarAPI {
  getCheckins = async (params: any) => {
    return axios.post(`api/babysitter-checkins`, {
      ...params,
    })
  }

  getCheckinsList = async (params: any) => {
    return axios.get(`api/babysitter-checkins-list`, {
      ...params,
    })
  }

  getWalletInfo = async () => {
    return axios.get(`api/babysitter-wallet-info`)
  }

  saveBankInfo = async (params: any) => {
    return axios.post(`api/babysitter-bank-info`, params)
  }

  getInvoiceAmount = async (params: any) => {
    return axios.get(`api/babysitter-invoice-amount`, params)
  }

  uploadInvoice = async (params: any) => {
    return axios.post(`api/babysitter-invoice-upload`, params)
  }

  saveInvoice = async (params: any) => {
    return axios.post(`api/babysitter-invoice-save`, params)
  }

  downloadInvoice = async (invoiceNumber: number) => {
    return axios.get(`api/babysitter-invoice-download/${invoiceNumber}`, {
      responseType: 'blob', // Set the response type to 'blob'
    })
  }
}

export const calendarsAPI = new CalendarAPI()
