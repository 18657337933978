import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { useHeaderContext } from '@/features/header/contexts/HeaderContext'
import {
  BabysitterProfileDetails,
  ClientProfileDetails,
} from '@/features/profile/components/ProfileDetails'
import { useUser } from '@/features/user'

export const Profile = () => {
  const { setKey } = useHeaderContext()
  const { t } = useTranslation()

  const { isBabysitter } = useUser()

  useEffect(() => {
    setKey({ base: true, title: 'profile', border: true })
  }, [])
  //dispatch(appState.pageTitle('profile'))

  return (
    <>
      <MegaTagTitle title={'profile'} />

      {isBabysitter() ? <BabysitterProfileDetails /> : <ClientProfileDetails />}
    </>
  )
}
