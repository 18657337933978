import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useBookingDrawerModuleContext } from '@/features/bookings/contexts'
import { useBookingPermissions } from '@/features/bookings/hooks'
import { bookingsData } from '@/features/bookings/store'
import { BookingModel } from '@/features/bookings/types/models'
import { RatingEntryPopupManagerControl } from '@/features/rating/components/RatingEntryPopupManager'
import { RatingSubmittedActionMeta } from '@/features/rating/hooks/useRatingEntryPopupModule'
import { useUser } from '@/features/user'

interface IProps {
  booking: BookingModel
}

export const RatingControl = ({ booking }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { isBabysitter } = useUser()

  const { isRatingManageAllow } = useBookingPermissions()

  const { babysitter_rating, client_rating } = booking

  const { onRatingSubmitted } = useBookingDrawerModuleContext()

  const onHandleRatingSubmitted = (rating: any, actionMeta: RatingSubmittedActionMeta) => {
    if (isBabysitter()) {
      dispatch(bookingsData.setClientRating(rating))
    } else {
      dispatch(bookingsData.setBabysitterRating(rating))
    }

    actionMeta = {
      ...actionMeta,
      payload: {
        bookingId: booking?.id,
      },
    }

    onRatingSubmitted && onRatingSubmitted(rating, actionMeta)
  }

  const rating = useMemo(() => {
    return isBabysitter() ? client_rating : babysitter_rating
  }, [babysitter_rating, client_rating, isBabysitter()])

  return (
    <RatingEntryPopupManagerControl
      bookingId={booking?.id}
      rating={rating}
      value={rating?.value}
      disabled={!isRatingManageAllow(booking)}
      ratingControlProps={{ size: 'lg' }}
      onRatingSubmitted={onHandleRatingSubmitted}
    />
  )
}
