export enum TAG_MANAGER_EVENT {
  REGISTER = 'register',
  BOOKING_WITHOUT_LOGIN = 'booking_without_login',
  NEW_BOOKING = 'newbooking',
  CARE_DETAILS = 'care_details',
  BEFORE_CHECKOUT = 'before_checkout',
  SUBMISSIONS_REGULAR = 'submissions_regular',
  SUBMISSIONS_ENTERTAINMENT = 'submissions_entertainment',
  SUBMISSIONS_NANNY = 'submissions_permanent_nanny',
  PURCHASE = 'purchase',

  PURCHASE_OCASIONAL = 'ocasional',
}
