import classNames from 'classnames'
import React from 'react'
import { Group, Text } from '@mantine/core'
import { ImageView } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { MessageType } from '@/features/chat/types/models'
import { usePopup } from '@/hooks'
import useStyles from './Message.styles'
import { Meta } from './Meta'

interface IProps {
  item: MessageType
}

export const Message = ({ item }: IProps) => {
  const { classes, cx } = useStyles()
  const { visible, open, close } = usePopup()
  const {
    appState: { mobileView },
  } = useAppState()

  const openImage = () => {
    if (mobileView) {
      open(true)
    } else {
      window.open(process.env.REACT_APP_BASE_URL + 'api/chats/chat-image/' + item?.image, '_blank')
    }
  }
  return (
    <div
      className={cx(classes.root, {
        [`${classes.highlight}`]: item.is_my,
      })}
    >
      {item?.image && (
        <Group className={classes.image}>
          <img
            className={classes.imageType}
            src={process.env.REACT_APP_BASE_URL + 'api/chats/chat-image/' + item?.image}
            alt="image"
            onClick={() => openImage()}
          />
        </Group>
      )}
      {item?.body && (
        <Text className={classNames('text-start', classes.bodyText)}>{item?.body}</Text>
      )}

      <div className={'flex justify-end'}>
        <Meta data={item} />
      </div>
      <ImageView opened={visible} onClose={close}>
        <img
          className={classes.imagePopup}
          src={process.env.REACT_APP_BASE_URL + 'api/chats/chat-image/' + item?.image}
          alt="image"
          onClick={() => openImage()}
        />
      </ImageView>
    </div>
  )
}
