import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    height: '72px',
    padding: '12px 16px',
    display: 'flex',
    width: '100%',

    [':hover']: {
      backgroundColor: theme.colors.gray[0],
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      borderBottom: '1px solid lightgrey',
      height: 'unset',
    },
  },

  rootActive: {
    backgroundColor: theme.colors.gray[0],
  },

  avatar: {
    marginRight: '16px',
  },

  body: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    padding: '4px 0',
  },

  meta: {
    height: '100%',
  },
}))
