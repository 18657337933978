import { createSlice } from '@reduxjs/toolkit'
import { ChatModel, Participant } from '@/features/chat/types/models'

interface IState {
  data: null | {
    id?: number
    participant?: Participant
  }
  backgroundUnreadMessages: boolean
  activeChat: null | ChatModel
}

const initialState: IState = {
  data: null,
  backgroundUnreadMessages: false,
  activeChat: null,
}

const chatConversationDataSlice = createSlice({
  name: 'chatConversationData',
  initialState,
  reducers: {
    initChat(state, action) {
      state.data = {
        ...action.payload,
      }
      state.activeChat = action.payload
    },
    initParticipant(state, action) {
      state.data = {
        participant: {
          ...action.payload,
        },
      }
    },
    setBackgroundUnreadMessages(state, action) {
      state.backgroundUnreadMessages = action.payload
    },
    updateData(state, action) {
      state.data = { ...state.data, ...action.payload }
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} initChat
 * @property {function} initParticipant
 * @property {function} setBackgroundUnreadMessages
 * @property {function} updateData
 * @property {function} cleanState
 */

export const chatConversationData = chatConversationDataSlice.actions

export default chatConversationDataSlice.reducer
