import * as Yup from 'yup'

export const validationSchemaBabysitter = Yup.object().shape({
  value: Yup.number().nullable().required('field.error.required'),
  person: Yup.number().nullable().required('field.error.required'),
  platform: Yup.number().nullable().required('field.error.required'),
})

export const validationSchemaClient = Yup.object().shape({
  value: Yup.number().nullable().required('field.error.required'),
})

export const getValidationSchema = ({ babysitter }: { babysitter: boolean }) => {
  if (babysitter) {
    return validationSchemaClient
  } else {
    return validationSchemaBabysitter
  }
}
