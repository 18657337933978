import React from 'react'
import { useTranslation } from 'react-i18next'
import { Center, Space, Text } from '@mantine/core'
import { Modal } from '@/components/Elements'
import { useUser } from '@/features/user'

interface IProps {
  children: React.ReactNode
  visible: boolean
  onClose: () => void
  title?: string
}

export const RatingEntryModal = ({ children, visible, onClose, title }: IProps) => {
  const { t } = useTranslation()

  const { isBabysitter } = useUser()

  title = title ? title : isBabysitter() ? t('rate_client') : t('rate_service')

  return (
    <>
      <Modal opened={visible} onClose={onClose} centered title={title}>
        <Center>
          <Text weight={600} size={'sm'}>
            {t('rating-info-message')}
          </Text>
        </Center>

        <Space h={'lg'} />

        {children}
      </Modal>
    </>
  )
}
