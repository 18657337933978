import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Chat } from '../chat'

export const ChatRoot = () => {
  return (
    <Routes>
      <Route index element={<Chat />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
