import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Alert, ContentCard } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { useChatBackgroundEffect, useChatConversationData } from '@/features/chat/hooks'
import { chatConversationData } from '@/features/chat/store'
import { EntryToolbar } from './EntryToolbar'
import { Header } from './Header'
import { MessagesContainer } from './MessagesContainer'
import useStyles from './Room.styles'
import { SplashScreen } from './SplashScreen'

export const Room = () => {
  const {
    appState: { mobileView },
  } = useAppState()
  const { classes } = useStyles()

  const dispatch = useDispatch()

  const { conversation } = useChatConversationData()

  useChatBackgroundEffect()

  useEffect(() => {
    return () => {
      dispatch(chatConversationData.cleanState())
    }
  }, [])

  return (
    <div className={classes.root}>
      {conversation ? (
        <>
          {!mobileView ? (
            <>
              <Header />
              <MessagesContainer />
              <EntryToolbar />
            </>
          ) : (
            <ContentCard className={'p-0'} footer={<EntryToolbar />} scrollable columnReverse>
              <MessagesContainer />
            </ContentCard>
          )}
        </>
      ) : (
        <SplashScreen />
      )}
    </div>
  )
}
