import React from 'react'
import { Participant } from '@/features/chat/types/models'
import { BabysitterUser, ClientUser, ROLE, User } from '@/features/user'

export const useUsersModel = () => {
  const getName = (user: User | BabysitterUser | ClientUser | Participant | null | undefined) => {
    if (user) {
      let result = user?.first_name

      if (user?.last_name) {
        result = result ? `${result} ${user?.last_name}` : user?.last_name
      }

      return result
    }
    return null
  }

  const isUserActive = (user?: User | BabysitterUser | ClientUser) => {
    if (user) {
      return user?.active && user?.approved
    }

    return false
  }

  return {
    getName,
    isUserActive,
  }
}
