import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { BasicLayout } from '@/components/Layouts'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { Confirmed } from '../confirmed'
import { New } from '../new'

export const BookingRoot = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={'new'} replace={true} />} />

      <Route
        path={'new'}
        element={
          <BasicLayout header={false}>
            <New bookingType={BOOKING_TYPE.ONETIME} />
          </BasicLayout>
        }
      />

      <Route
        path={'confirmed'}
        element={
          <BasicLayout>
            <Confirmed />
          </BasicLayout>
        }
      />

      {/*<Route
        path={'checkout'}
        element={
          <BasicLayout>
            <Checkout />
          </BasicLayout>
        }
      />*/}

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
