import React from 'react'
import { MegaTagTitle } from '@/components/Elements'
import { BookingLayout } from '@/features/booking/components/Booking'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'

interface IProps {
  bookingType: BOOKING_TYPE
}
export const New = ({ bookingType }: IProps) => {
  return (
    <>
      <MegaTagTitle title={'new_booking'} />

      <BookingLayout bookingType={bookingType} />
    </>
  )
}
