import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  wrapper: {
    [`& svg path`]: {
      strokeWidth: '2px',
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '0 12vw',
    },
  },
}))
