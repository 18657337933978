import React from 'react'
import { useBookingPermissions } from '@/features/bookings/hooks'
import { BookingModel } from '@/features/bookings/types/models'
import { BookingDetails, RatingControl } from './Controls'

interface IProps {
  config: {
    details: boolean
    rating: boolean
  }
  booking: BookingModel
  onView: (id: number) => void
  ratingControlProps?: any
}

const defaultConfig = {
  details: true,
  rating: false,
}

export const Footer = ({ config, booking, onView, ratingControlProps }: IProps) => {
  config = {
    ...defaultConfig,
    ...config,
  }

  const { isRatingAllow } = useBookingPermissions()

  return (
    <div className={'flex flex-wrap gap-3 grow'}>
      {config?.rating && isRatingAllow(booking) && (
        <RatingControl booking={booking} {...ratingControlProps} />
      )}

      {config?.details && (
        <div className={'flex flex-wrap gap-3 grow justify-end'}>
          <BookingDetails id={booking?.id} onView={onView} />
        </div>
      )}
    </div>
  )
}
