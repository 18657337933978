import { BOOKING_STATUS } from '@/features/bookings/consts/booking'
import { SPECIFIC_BOOKING_ALIAS } from '@/features/bookings/consts/specific-bookings'

export const getSpecificFetchParams = (alias: SPECIFIC_BOOKING_ALIAS) => {
  let params: any = {}
  if (alias === SPECIFIC_BOOKING_ALIAS.UPCOMING) {
    params = {
      includes: ['babysitter'],
      filters: {
        statuses: [
          BOOKING_STATUS.UNPAID,
          BOOKING_STATUS.WAITING_PAYMENT,
          BOOKING_STATUS.WAITING_BABYSITTER,
          BOOKING_STATUS.BABYSITTER_NOT_FOUND,
          BOOKING_STATUS.UPCOMING,
          BOOKING_STATUS.REQUEST_FINISH,
          BOOKING_STATUS.PAYMENT_LINK_ERROR,
        ],
      },
    }
  } else if (alias === SPECIFIC_BOOKING_ALIAS.HISTORY) {
    params = {
      includes: ['babysitter', 'babysitterRating'],
      filters: {
        statuses: [
          BOOKING_STATUS.CANCELED_CLIENT,
          BOOKING_STATUS.CANCELED_BABYSITTER,
          BOOKING_STATUS.FINISHED,
          BOOKING_STATUS.CANCELED_LATE_PAYMENT,
        ],
      },
    }
  }

  return params
}
