import { createSlice } from '@reduxjs/toolkit'
import { BookingModel } from '@/features/bookings/types/models'

interface IState {
  list: BookingModel[]
  loading: boolean
  error: any | null
  page: number
  meta: any | null
}

const initialState: IState = {
  list: [],
  loading: false,
  error: null,
  page: 1,
  meta: null,
}

const bookingsBookingsSpecificListSlice = createSlice({
  name: 'bookingsBookingsSpecificList',
  initialState,
  reducers: {
    getList(state, action) {
      state.loading = true
      state.error = null
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    updateListItem(state, action) {
      state.list = state.list.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return item
        }
      })
    },
    updateBabysitter(state, action) {
      state.list = state.list.map((item) => {
        if (item?.babysitter?.id === action.payload.id) {
          return {
            ...item,
            babysitter: {
              ...item.babysitter,
              ...action.payload,
            },
          }
        } else {
          return item
        }
      })
    },
    setBookingBabysitterRating(state, action) {
      const { id, rating } = action.payload

      state.list = state.list.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            babysitter_rating: rating,
          }
        } else {
          return item
        }
      })
    },
    cleanState() {
      return initialState
    },
  },
})

export const bookingsBookingsSpecificList = bookingsBookingsSpecificListSlice.actions

export default bookingsBookingsSpecificListSlice.reducer
