import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentIntentResult, StripePaymentElementOptions } from '@stripe/stripe-js'
import React, { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Anchor } from '@mantine/core'
import { Checkbox as MantineCheckbox, Text } from '@mantine/core'
import { Button } from '@/components/Elements'
import { EXTERNAL_LINK } from '@/const/core'
import { useAppState } from '@/features/app/hooks'
import { bookingsAPI } from '@/features/bookings/api'
import useStyles from './CheckoutForm.styles'

export default function CheckoutForm({
  booking,
  paymentId,
  goNext /*setOcasionalPaymentDone*/,
}: any) {
  const { t } = useTranslation()

  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState<any>(null)
  const [accepted, setAccepted] = useState(false)
  const [showAcceptError, setShowAcceptedError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { classes } = useStyles()

  const {
    appState: { mobileView },
  } = useAppState()
  const submit = async (e: FormEvent) => {
    e.preventDefault()

    if (!accepted) {
      setShowAcceptedError(true)
      return
    } else {
      setShowAcceptedError(false)
    }
    if (!stripe || !elements) {
      console.log('stripe: ' + !stripe + ' elements ' + !elements)
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    try {
      const { error, paymentIntent }: PaymentIntentResult = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/booking/confirmed`,
        },
        redirect: 'if_required',
      })

      if (error?.type === 'card_error' || error?.type === 'validation_error') {
        setMessage(error?.message)
      } else {
        await bookingsAPI.setBookingConfirmed(booking.id, paymentId)
        goNext()
        //setOcasionalPaymentDone(true)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const options: StripePaymentElementOptions = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true,
    },
  }

  return (
    <form id="payment-form" onSubmit={submit}>
      <PaymentElement options={options} id="payment-element" />

      <div className={mobileView ? 'mt-5' : ' mt-3'}>
        <MantineCheckbox
          checked={accepted}
          onChange={(event) => setAccepted(event.currentTarget.checked)}
          label={
            <>
              {t('register_terms_label')}{' '}
              <Anchor component={'a'} href={EXTERNAL_LINK.TERMS_AND_CONDITIONS} target={'_blank'}>
                {t('register_terms_text')}
              </Anchor>
            </>
          }
          mb={'md'}
        />
        {showAcceptError && (
          <Text size={13} color={'red'}>
            Terms must be accepted
          </Text>
        )}
      </div>

      {mobileView ? (
        <div className={'flex justify-between items-center'}>
          <div>
            <h2
              className="price-calculation-tooltip flex gap-4 items-center cursor-pointer"
              data-pr-position="top"
            >
              <span className={classes.price}>{t('price')}</span>
            </h2>
            <h2
              className="price-calculation-tooltip flex gap-4 items-center cursor-pointer"
              data-pr-position="top"
            >
              <span>€{(booking.total_amount / 100).toFixed(2)}</span>
            </h2>
          </div>
          <div className="mt-8">
            <Button
              loading={isLoading}
              disabled={isLoading || !stripe || !elements}
              fullWidth
              type="submit"
            >
              {t('pay')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="mt-8">
          <Button
            loading={isLoading}
            disabled={isLoading || !stripe || !elements}
            fullWidth
            type="submit"
          >
            {t('pay')}
          </Button>
        </div>
      )}
      {/* Show any error or success messages */}
      {message && (
        <div className=" text-primary-500 text-sm mt-1 font-quicksand" id="payment-message">
          {message}
        </div>
      )}
    </form>
  )
}
