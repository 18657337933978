import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { BookingTimePeriodDisplay } from '@/features/bookings/components/Elements'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { bookingHelper } from '@/features/bookings/helpers'
import { BookingModel } from '@/features/bookings/types/models'
import { panelGeneral, panelLoader } from '@/features/panel/store'
import { DateUtils } from '@/utils'
import useStyles from './DateBlock.styles'

const { bookingModel } = bookingHelper

interface IProps {
  data: BookingModel
}

export const DateBlock = ({ data }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()
  const [dateEndToRender, setDateEndToRender] = useState(data.date_end)
  const { t } = useTranslation()

  const { classes } = useStyles()
  const timePeriod = bookingModel.getTimePeriod(data)

  const { type, date_start, date_end, timetable } = data

  useEffect(() => {
    if (
      (type === BOOKING_TYPE.ONETIME || type === BOOKING_TYPE.ANIMATION) &&
      timetable?.onetime_ranges &&
      timetable.onetime_ranges.length > 0
    ) {
      let maxDate = moment(date_end)
      timetable?.onetime_ranges?.forEach(function (range: any) {
        if (maxDate.isBefore(moment(range.range_date_end))) {
          maxDate = moment(range.range_date_end)
          setDateEndToRender(range.range_date_end)
        }
      })
    }
  }, [])
  const renderDate = (date: string) => {
    const dayWeek = DateUtils.format(date, 'ddd')
    const day = DateUtils.format(date, 'D')
    const month = DateUtils.format(date, 'MMM')

    return (
      <div className={'flex flex-col items-center'}>
        <Text size={15} weight={700} color={'secondary'}>
          {dayWeek}
        </Text>
        <Text size={32} weight={700}>
          {day}
        </Text>
        <Text weight={500} color={'gray.6'}>
          {month}
        </Text>
      </div>
    )
  }

  const renderDateMobile = (date: string) => {
    const dayWeek = DateUtils.format(date, 'ddd')
    const day = DateUtils.format(date, 'D')
    const month = DateUtils.format(date, 'MMM')

    return (
      <div className={'flex gap-1'}>
        <Text size={14} weight={700} color={'secondary'}>
          {dayWeek}
        </Text>
        <Text size={14} weight={700}>
          {day}
        </Text>
        <Text size={14} weight={500} color={'gray.6'}>
          {month}
        </Text>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {mobileView && (
        <div className={classes.dateBlock}>
          <div className={'flex gap-1'}>
            {date_start === dateEndToRender ? (
              renderDateMobile(date_start)
            ) : (
              <>
                {renderDateMobile(date_start)}

                <Text size={14} weight={700}>
                  {' - '}
                </Text>

                {renderDateMobile(dateEndToRender)}
              </>
            )}
            {timePeriod && (
              <>
                <Text size={14} weight={700}>
                  {', '}
                </Text>
                <Text size={14} weight={700}>
                  <BookingTimePeriodDisplay timePeriod={timePeriod} />
                </Text>
              </>
            )}
          </div>
        </div>
      )}

      {!mobileView && (
        <>
          <div className={classes.dateBlock}>
            {date_start === dateEndToRender ? (
              renderDate(date_start)
            ) : (
              <div className={'flex gap-2 items-center justify-center'}>
                {renderDate(date_start)}

                <Text size={32} weight={700}>
                  {' - '}
                </Text>

                {renderDate(dateEndToRender)}
              </div>
            )}
          </div>
          <div className={classes.divider} />
        </>
      )}
    </div>
  )
}
