import React from 'react'
import { BabysitterRatingModel, ClientRatingModel } from '@/features/rating/types/models'
import { ROLE } from '@/features/user'

export type RatingSubmittedActionMeta = {
  action: 'create' | 'edit' | 'remove'
  entity: ROLE.Client | ROLE.Babysitter | null
  payload?: any
}

export interface IProps {
  onRatingSubmitted: (
    rating: ClientRatingModel | BabysitterRatingModel | null,
    actionMeta: RatingSubmittedActionMeta
  ) => void
}

export const useRatingEntryPopupModule = ({ onRatingSubmitted }: IProps) => {
  return {
    onRatingSubmitted,
  }
}
