import axios from 'axios'
import { SubmitBabysitterRatingDTO } from './dto'

class BabysitterRatingAPI {
  updateRating = async (id: number, data: SubmitBabysitterRatingDTO) => {
    return axios.put(`api/babysitter-ratings/${id}`, data)
  }

  deleteRating = async (id: number) => {
    return axios.delete(`api/babysitter-ratings/${id}`)
  }
}

export const babysitterRatingAPI = new BabysitterRatingAPI()
