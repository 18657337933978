import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  bookingsRatingBabysitterCreatePA,
  bookingsRatingClientCreatePA,
} from '@/features/bookings/store'
import { useRatingEntryPopupModuleContext } from '@/features/rating/contexts'
import { useUser } from '@/features/user'
import { useNotify } from '@/hooks'
import { RatingEntryForm } from '../../RatingEntryForm'

interface IProps {
  bookingId: number
  onClose: () => void
  onDestroy: () => void
}

export const RatingEntryCreateContainer = ({ bookingId, onClose, onDestroy }: IProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => onDestroy()
  }, [])

  const { onRatingSubmitted } = useRatingEntryPopupModuleContext()

  const { user, isBabysitter } = useUser()

  const onSuccess = (data: any) => {
    onRatingSubmitted && onRatingSubmitted(data, { action: 'create', entity: user?.role })
  }

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }

    const action = isBabysitter() ? bookingsRatingClientCreatePA : bookingsRatingBabysitterCreatePA

    const data = await dispatch(action.request({ id: bookingId, params }))
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess(data)
  }

  return (
    <>
      <RatingEntryForm onSubmit={onSubmit} onCancel={onClose} />
    </>
  )
}
