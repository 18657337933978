import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import _ from 'lodash'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { chatsAPI } from '@/features/chat/api'
import { chatHelper } from '@/features/chat/helpers'
import { ChatModel, MessageType } from '@/features/chat/types/models'
import i18n from '@/i18n'
import { RootState } from '@/store'
import { chatChatsList } from './slice'

const chatsListState = (state: RootState) => state.chat.chats.list

function* getList() {
  try {
    const response: AxiosResponse = yield call(chatsAPI.getChats)

    const { data } = response

    yield put(chatChatsList.getListSuccess(data))
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    // console.log('chatChatsList getListError', message)
    yield put(chatChatsList.getListError(message))
  }
}

// create/updated chat when message received or submit message
export function* chatUpdateOrCreate({
  payload: { message, chat, isCurrentChat },
}: PayloadAction<{ message: MessageType; chat: ChatModel; isCurrentChat?: boolean }>) {
  const listState: {
    list: ChatModel[]
    isLoaded: boolean
  } = yield select(chatsListState)

  if (listState.isLoaded) {
    const chatItem = _.find(listState.list, { id: message?.chat_id })

    if (chatItem) {
      // if exist - update chat item
      const item = chatHelper.chats.updateChatItem({ ...chatItem }, message, chat, isCurrentChat)
      yield put(
        chatChatsList.updateItem({
          id: chatItem.id,
          ...item,
        })
      )
    } else {
      // add new chat item
      const newChat = chatHelper.chats.createChatItemBasedOnMessage(message, chat, isCurrentChat)
      yield put(chatChatsList.addItem(newChat))
    }
  }
}

// add chat when received socket event
export function* onChatAdd({ payload: { chat } }: PayloadAction<{ chat: ChatModel }>) {
  const listState: {
    isLoaded: boolean
  } = yield select(chatsListState)

  if (listState.isLoaded) {
    yield put(chatChatsList.addItem(chat))
  }
}

// remove chat when received socket event
export function* onChatRemove({ payload: { id } }: PayloadAction<{ id: number }>) {
  const listState: {
    isLoaded: boolean
  } = yield select(chatsListState)

  if (listState.isLoaded) {
    yield put(chatChatsList.removeItem({ id }))
  }
}

// updated chat when received socket event
export function* onChatUpdate({ payload: { chat } }: PayloadAction<{ chat: ChatModel }>) {
  const listState: {
    isLoaded: boolean
  } = yield select(chatsListState)

  if (listState.isLoaded) {
    yield put(chatChatsList.updateItem(chat))
  }
}

function* watchGetList() {
  yield takeLatest(chatChatsList.getList, getList)
}

export function* listSaga() {
  yield all([fork(watchGetList)])
}
