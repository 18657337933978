import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBookingPermissions } from '@/features/bookings/hooks'
import { BookingModel } from '@/features/bookings/types/models'
import { RatingEntryPopupManagerControl } from '@/features/rating/components/RatingEntryPopupManager'
import { RatingSubmittedActionMeta } from '@/features/rating/hooks/useRatingEntryPopupModule'

interface IProps {
  booking: BookingModel
  onRatingSubmitted: (rating: any, actionMeta: any) => void
}

export const RatingControl = ({ booking, onRatingSubmitted }: IProps) => {
  const { t } = useTranslation()

  const { isRatingManageAllow } = useBookingPermissions()

  const { babysitter_rating } = booking

  const onHandleRatingSubmitted = (rating: any, actionMeta: RatingSubmittedActionMeta) => {
    actionMeta = {
      ...actionMeta,
      payload: {
        bookingId: booking?.id,
      },
    }
    onRatingSubmitted(rating, actionMeta)
  }

  return (
    <RatingEntryPopupManagerControl
      bookingId={booking?.id}
      rating={babysitter_rating}
      value={babysitter_rating?.person}
      disabled={!isRatingManageAllow(booking)}
      ratingControlProps={{ size: 'lg' }}
      onRatingSubmitted={onHandleRatingSubmitted}
    />
  )
}
