import classNames from 'classnames'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { BackgroundImage, Stack, Text, Title } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { ModalOrDialogSwitch } from '@/components/Elements/MobileWebSwitchs'
import { usePopup } from '@/hooks'
import useStyles from './BookLayout.styles'

interface IProps {
  title: string
  desc: string
  image: any
}
export const BookEventFAQ = ({ title, desc, image }: IProps) => {
  const { hovered, ref } = useHover()
  const { classes } = useStyles()

  const { visible, open, close } = usePopup()

  return (
    <BackgroundImage src={image} radius="sm" onClick={open}>
      <div ref={ref} className={hovered ? 'hover-mask' : ''}>
        <div className={classes.backgroundImageFAQ} />
        <div className={classes.faqBackground}>
          <div className={classes.faqContent}>
            <Stack>
              <Title order={3}>FAQ</Title>
              <Title order={3}>{title}</Title>
            </Stack>
          </div>
        </div>
      </div>
      <ModalOrDialogSwitch title={title} opened={visible} headerBorder={true} onClose={close}>
        <div className="m-4">
          <Text weight={600}>{ReactHtmlParser(desc)}</Text>
        </div>
      </ModalOrDialogSwitch>
    </BackgroundImage>
  )
}
