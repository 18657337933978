import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Space, Title } from '@mantine/core'
import cat from '@/assets/images/illustrations/cat.png'
import { ButtonLink } from '@/components/Elements'
import useStyles from './HomeContent.styles'

export const HomeContent = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div>
      <div className={classes.wrap}>
        <Title>{t('welcome')}</Title>

        <div className={'mt-4 mb-4 flex'}>
          <Title order={4}>{t('choose_user_role')}:</Title>
        </div>
      </div>

      <div className={classes.whiteWrap}>
        <div className={classes.buttonsWrap}>
          <Group>
            <ButtonLink fullWidth radius={32} color={'secondary'} to={'/auth/client'}>
              {t('client')}
            </ButtonLink>

            <ButtonLink fullWidth radius={32} to={'/login/babysitter'}>
              {t('baby_sister')}
            </ButtonLink>
          </Group>
        </div>

        <Space h={'md'} />

        <div className={classes.imgWrap}>
          <img src={cat} alt={'cat'} />
        </div>
      </div>
    </div>
  )
}
