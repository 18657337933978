import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Space, Title } from '@mantine/core'
import { Alert, Card } from '@/components/Elements'
import { useQuery } from '@/hooks'
import { authLoginPA, authResetPasswordPA } from '../../../store'
import { FormValues, RegisterPasswordForm } from './RegisterPasswordForm'

type SubmitParams = FormValues & {
  token: string
  email: string
}
type LoginParams = {
  password: string
  email: string
  remember: boolean
}

export const RegisterPasswordContainer = () => {
  const { t } = useTranslation()

  const query = useQuery()
  const { token } = useParams()

  const dispatch = useDispatch()

  const [success, setSuccess] = useState<string | null>(null)

  const handleSubmit = async (values: FormValues) => {
    setSuccess(null)

    const params: SubmitParams = {
      ...values,
      token: token || '',
      email: query.get('email') || '',
    }

    const { message } = await dispatch(authResetPasswordPA.request(params))

    setSuccess(message || t('success'))

    console.log('Try to login...')
    const loginParams: LoginParams = {
      email: query.get('email') || '',
      password: values.password || '',
      remember: true,
    }

    await dispatch(authLoginPA.request(loginParams))
  }

  return (
    <Card>
      <Title order={3}>{t('register_password')}</Title>

      <Space h={'lg'} />

      {success && (
        <Alert type={'success'} mb={'sm'}>
          {success}
        </Alert>
      )}

      <RegisterPasswordForm onSubmit={handleSubmit} />

      <Space h={'lg'} />
    </Card>
  )
}
