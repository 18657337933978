import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppState } from '@/features/app/hooks'
import { BookingInfoAlert, BookingNewBadge } from '@/features/bookings/components/Elements'
import { bookingHelper } from '@/features/bookings/helpers'
import { useBookingData, useBookingPermissions } from '@/features/bookings/hooks'
import { BookingModel } from '@/features/bookings/types/models'
import { RatingControl } from './Controls'

const { isCanceledBabysitter } = bookingHelper.status

export const Header = () => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const { booking } = useBookingData()
  const { isRatingAllow } = useBookingPermissions()

  const { status, is_new } = booking ?? {}

  const canceledByBabysitter = isCanceledBabysitter(status)

  const visible =
    booking &&
    ((!mobileView && isRatingAllow(booking)) ||
      booking?.has_unpaid_invoice ||
      canceledByBabysitter ||
      is_new)

  return (
    <>
      {visible && (
        <div className={mobileView ? 'flex flex-col p-3 gap-3' : 'flex flex-col mb-3 gap-3'}>
          {!mobileView && isRatingAllow(booking) && (
            <div className={'flex flex-wrap'}>
              <RatingControl booking={booking as BookingModel} />
            </div>
          )}

          {is_new && (
            <div>
              <BookingNewBadge />
            </div>
          )}

          {booking?.has_unpaid_invoice && <BookingInfoAlert type={'has_unpaid_invoice'} />}

          {canceledByBabysitter && <BookingInfoAlert type={'canceled_babysitter'} />}
        </div>
      )}
    </>
  )
}
