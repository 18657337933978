import _ from 'lodash'
import { ChatModel, MessageType } from '@/features/chat/types/models'

export const createChatItemBasedOnMessage = (
  message: MessageType,
  chat: ChatModel,
  isCurrentChat = false
) => {
  const { chat_id, ...messageData } = message

  // const { is_group } = chat
  const is_group = false

  const isReceived = !messageData.is_my

  const result = {
    ...chat,
    id: chat_id,
    last_message: messageData,
    unread_count: isReceived && !isCurrentChat ? 1 : 0,
  }

  if (!is_group) {
    const { avatar, id, first_name, last_name, role } = chat.participant
    result.participant = {
      avatar,
      id,
      first_name,
      last_name,
      role,
    }
  }

  return result
}

export const updateChatItem = (
  item: ChatModel,
  message: MessageType,
  chat: ChatModel,
  isCurrentChat = false
) => {
  const { chat_id, ...messageData } = message || {}
  const isReceived = !messageData.is_my

  const result: any = {
    last_message: _.isEmpty(messageData) ? null : messageData,
  }

  if (isReceived) {
    result.participant = chat?.participant
    result.unread_count = isCurrentChat ? item.unread_count : item.unread_count + 1
  }

  return result
}
