import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { profileBabysitterUpdatePA } from '@/features/profile/store'
import { useNotify } from '@/hooks'
import { EditForm } from './EditForm'

interface IProps {
  onCancel: () => void
  editorType?: EditorType
}

export enum EditorType {
  ALL,
  IMAGE,
  GENERAL,
  ABOUT_ME,
  LANGUAGE,
  SKILLS,
  CERTIFICATIONS,
  SETTINGS,
}
export const BabysitterProfileEdit = ({ onCancel, editorType = EditorType.ALL }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    await dispatch(profileBabysitterUpdatePA.request(values))
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })
    onCancel()
  }

  return (
    <>
      <EditForm editorType={editorType} onCancel={onCancel} onSubmit={onSubmit} />
    </>
  )
}
