import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import bold from '@/assets/fonts/Quicksand/Quicksand-Bold.ttf'
import light from '@/assets/fonts/Quicksand/Quicksand-Light.ttf'
import medium from '@/assets/fonts/Quicksand/Quicksand-Medium.ttf'
import regular from '@/assets/fonts/Quicksand/Quicksand-Regular.ttf'
import semiBold from '@/assets/fonts/Quicksand/Quicksand-SemiBold.ttf'
import { ContentCard, MegaTagTitle } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import useStyles from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/BookingWizardForm.styles'
import { Layout } from '@/features/booking/components/BookingConfirmed'
import { bookingsAPI } from '@/features/bookings/api'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { useLang } from '@/hooks'
import { BookingCheckoutDetails } from './BookingCheckoutDetails'
import CheckoutForm from './CheckoutForm'

interface IProps {
  goNext: () => void
}

export const Checkout = ({ goNext }: IProps) => {
  const { t } = useTranslation()
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')
  )
  const [clientSecret, setClientSecret] = useState(undefined)
  const [paymentId, setPaymentId] = useState(undefined)
  const { booking } = useSelector((state: any) => state.booking.checkout)

  const { classes } = useStyles()
  const { lang } = useLang()
  const {
    appState: { mobileView },
  } = useAppState()
  const navigate = useNavigate()

  const viewport = useRef<HTMLDivElement>()

  const scrollToTop = () => {
    if (viewport?.current) {
      viewport.current.scrollTo({ top: 0 })
    } else {
      window.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    scrollToTop()
    manageCheckout()
  }, [])

  const manageCheckout = async () => {
    if (booking.type == BOOKING_TYPE.ONETIME) {
      await createPaymentIntent()
    }

    console.log('Checkout booking?:' + JSON.stringify(booking))
    if (!booking) {
      navigate('/bookings')
    }
  }
  const createPaymentIntent = async () => {
    const { data } = await bookingsAPI.createPaymentIntentV2(booking?.id)
    setClientSecret(data.client_secret)
    setPaymentId(data.id)
  }

  const options: StripeElementsOptions = {
    clientSecret,
    locale: lang.startsWith('pt') ? 'pt' : 'en',
    fonts: [
      {
        family: 'Quicksand',
        src: `url('${light}')`,
        weight: '300',
      },
      {
        family: 'Quicksand',
        src: `url('${regular}')`,
        weight: '400',
      },
      {
        family: 'Quicksand',
        src: `url('${medium}')`,
        weight: '500',
      },
      {
        family: 'Quicksand',
        src: `url('${semiBold}')`,
        weight: '600',
      },
      {
        family: 'Quicksand',
        src: `url('${bold}')`,
        weight: '700',
      },
    ],
    appearance: {
      theme: 'flat',
      variables: {
        colorPrimaryText: '#e26247',
        colorBackground: '#ffffff',
        colorText: '#000000',
        fontSizeBase: mobileView ? '14px' : '16px',
        fontFamily: 'Quicksand',
        fontSizeSm: '16px',
        focusBoxShadow: '0',
      },
      rules: {
        '.Label': {
          fontWeight: 'bold',
          fontFamily: 'Quicksand',
          margin: '8px 0',
        },
        '.Tab--selected': {
          borderColor: '#e26247',
        },
        '.Input': {
          border: '2px solid lightgrey',
          fontSize: mobileView ? '14px' : '16px',
        },
        '.Input:focus': {
          border: '2px solid #e26247',
          outline: 'none',
          boxShadow: '0',
        },
        '.Input--invalid': {
          border: '2px solid #df1b41',
          outline: 'none',
          boxShadow: '0',
        },
        '.AccordionItem': {
          fontSize: '16px',
        },
        '.AccordionItem--selected': {
          color: '#e26247',
        },
        '.Error': {
          fontSize: mobileView ? '14px' : '16px',
        },
      },
    },
  }
  /*
  const goNext = () => {
    navigate('/booking/confirmed')
  }
 */
  return (
    <ContentCard
      title={
        booking.type == BOOKING_TYPE.ONETIME /*&& !ocasionalPaymentDone*/ ? t('payment_method') : ''
      }
      scrollable={!mobileView}
      viewport={viewport}
      className={classes.cardWrapper}
    >
      <div className={classes.wrapper}>
        <MegaTagTitle title={'booking_confirmed'} />

        <Layout>
          <BookingCheckoutDetails />

          {/*!user && !clientSecret && (
          <form className="flex flex-col gap-4 m-0 mb-8" onSubmit={handleSubmit(onSubmit)}>
            <div className=" text-xl font-bold"> {t('fill_contact_details')}</div>
            <div className="flex flex-col gap-2">
              <label htmlFor="first_name">{t('first_name')}</label>
              <InputText
                id="first_name"
                aria-describedby="first_name-help"
                {...register('first_name', { required: t('field.error.required') })}
              />
              {errors.first_name?.message && (
                <small id="first_name-help">{errors.first_name?.message + ''}</small>
              )}
            </div>
            <div className="flex flex-col gap-2 p-invalid">
              <label htmlFor="last_name">{t('last_name')}</label>
              <InputText
                id="last_name"
                aria-describedby="last_name-help"
                {...register('last_name', { required: t('field.error.required') })}
              />
              {errors.last_name?.message && (
                <small className="p-invalid" id="last_name-help">
                  {errors.last_name?.message + ''}
                </small>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="email">{t('email')}</label>
              <InputText
                id="email"
                aria-describedby="email-help"
                {...register('email', {
                  required: t('field.error.required'),
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: t('field.error.invalid'),
                  },
                })}
              />
              {errors.email?.message && <small id="email-help">{errors.email?.message + ''}</small>}
            </div>

            <div className="mt-8">
              <Button loading={loading} fullWidth type="submit">
                {t('proceed_to_payment')}
              </Button>
            </div>
          </form>
        )*/}

          {!!clientSecret && (
            /*!ocasionalPaymentDone &&*/ <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                booking={booking}
                paymentId={paymentId}
                goNext={goNext}
                /*setOcasionalPaymentDone={setOcasionalPaymentDone}*/
              />
              {/*!user && (
              <div className="mt-8">
                <Button
                  onClick={() => setClientSecret(undefined)}
                  loading={loading}
                  fullWidth
                  type="submit"
                >
                  {t('update_contact_details')}
                </Button>
              </div>
            )*/}
            </Elements>
          )}
          {/*ocasionalPaymentDone && <BookingConfirmedContainer goNext={goNext} />*/}
        </Layout>
      </div>
    </ContentCard>
  )
}
