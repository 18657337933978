import React from 'react'
import { useChatConversationData } from '@/features/chat/hooks'
import { ROLE } from '@/features/user'
import { ActionBar } from './ActionBar'
import useStyles from './Header.styles'
import { Info } from './Info'

export const Header = () => {
  const { classes, cx } = useStyles()

  const { getParticipant } = useChatConversationData()

  const participant = getParticipant()

  const viewProfile = participant?.role === ROLE.Babysitter

  return (
    <div className={cx(classes.root, 'flex flex-wrap gap-4 items-center')}>
      <div className={'flex grow'}>
        <Info />
      </div>

      {viewProfile && (
        <div className={'self-end'}>
          <ActionBar config={{ viewProfile }} />
        </div>
      )}
    </div>
  )
}
