import React from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from '@mantine/core'
import { ModalOrDrawerSwitch } from '@/components/Elements/MobileWebSwitchs'
import { useUser } from '@/features/user'
import { PasswordContainer } from './PasswordContainer'
import { ProfileDeactivation } from './ProfileDeactivation'
import { ProfileDelete } from './ProfileDelete'

interface IProps {
  visible: boolean
  onClose: () => void
}

export const ProfileSettings = ({ visible, onClose }: IProps) => {
  const { t } = useTranslation()

  const { isBabysitter, isClient } = useUser()

  return (
    <>
      <ModalOrDrawerSwitch opened={visible} onClose={onClose} title={t('settings')}>
        <PasswordContainer onCancel={onClose} />

        <Space h={'xl'} />

        {isBabysitter() && <ProfileDeactivation />}

        {isClient() && <ProfileDelete />}
      </ModalOrDrawerSwitch>
    </>
  )
}
