import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { Participant } from '@/features/chat/types/models'
import { useUsersModel } from '@/features/users/hooks'
import useStyles from './Name.styles'

interface IProps {
  participant: Participant
}

export const Name = ({ participant }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const { getName } = useUsersModel()

  return (
    <Text weight={600} className={cx(classes.name, 'truncate')}>
      {getName(participant)}
    </Text>
  )
}
