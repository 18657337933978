import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { SelectController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'

export const HoursPerWeekFormSection = () => {
  const { t } = useTranslation()

  const { control, watch, setValue } = useFormContext()

  const {
    appState: { mobileView },
  } = useAppState()

  const optionsSelect = [
    {
      value: '0-16',
      label: '0-16',
    },
    {
      value: '16-40',
      label: '16-40',
    },
    {
      value: '+40',
      label: '+40',
    },
  ]

  return (
    <Grid>
      <Grid.Col span={mobileView ? 6 : 4}>
        <SelectController
          control={control}
          name={'hours_per_week'}
          id={'hours_per_week'}
          label={t('hours_per_week')}
          size={mobileView ? 'md' : 'lg'}
          placeholder={t('hours_per_week')}
          mb={'md'}
          data={optionsSelect}
          styles={
            mobileView
              ? {
                  label: {
                    fontWeight: 'bold',
                    fontSize: '16px !important',
                  },
                }
              : {}
          }
        />
      </Grid.Col>
    </Grid>
  )
}
