import { IconTrash } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Elements'
import { useRatingEntryPopupModuleContext } from '@/features/rating/contexts'
import { useRatingRemove } from '@/features/rating/hooks'
import { BabysitterRatingModel, ClientRatingModel } from '@/features/rating/types/models'
import { useUser } from '@/features/user'

interface IProps {
  rating?: BabysitterRatingModel | ClientRatingModel
}

export const RatingEntryRemoveButton = ({ rating }: IProps) => {
  const { t } = useTranslation()

  const { onRatingSubmitted } = useRatingEntryPopupModuleContext()

  const { loading, onSubmit } = useRatingRemove()

  const { user } = useUser()

  const onSuccess = () => {
    onRatingSubmitted && onRatingSubmitted(null, { action: 'remove', entity: user?.role })
  }

  const onRemove = async () => {
    await onSubmit(rating?.id as number, onSuccess)
  }

  return (
    <>
      <Button
        mt={'lg'}
        fullWidth
        variant={'subtle'}
        onClick={onRemove}
        leftIcon={<IconTrash />}
        loading={loading}
      >
        {t('remove_feedback')}
      </Button>
    </>
  )
}
