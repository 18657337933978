import axios from 'axios'
import { UploadProfileAvatarDTO } from './dto'

class ProfileAvatarAPI {
  uploadAvatar = async (data: UploadProfileAvatarDTO) => {
    const formData = new FormData()
    formData.append('avatar', data.avatar)

    return axios.post('api/profile/avatar', formData)
  }

  deleteAvatar = async () => {
    return axios.delete('api/profile/avatar')
  }
}

export const profileAvatarAPI = new ProfileAvatarAPI()
