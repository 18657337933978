import React from 'react'
import { useTranslation } from 'react-i18next'
import { RatingEntryModal } from '../RatingEntryModal'
import { RatingEntryCreateContainer } from './RatingEntryCreateContainer'

interface IProps {
  bookingId: number
  visible: boolean
  onClose: () => void
  onDestroy: () => void
}

export const RatingEntryCreate = ({ bookingId, visible, onClose, onDestroy }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <RatingEntryModal visible={visible} onClose={onClose}>
        <RatingEntryCreateContainer bookingId={bookingId} onClose={onClose} onDestroy={onDestroy} />
      </RatingEntryModal>
    </>
  )
}
