import { PayloadAction } from '@reduxjs/toolkit'
import { all, fork, put, select, takeEvery } from 'redux-saga/effects'
import { selectConversationParticipantId } from '@/features/chat/store/conversation/data/selectors'
import { chatConversationMessagesList } from '@/features/chat/store/conversation/messages/list/slice'
import { RootState } from '@/store'
import { chatSocketOnChatSeen } from './actions'

const participantIdState = (state: RootState) => selectConversationParticipantId(state)

function* onChatSeen({ payload: { data } }: PayloadAction<{ data: any }>) {
  const { chat_id, user } = data

  const participantId: number = yield select(participantIdState)
  const isGroup = false

  const isCurrentChat =
    // (isGroup && chat_id === groupChatId) ||
    !isGroup && user.id === participantId

  if (isCurrentChat) {
    // mark messages as seen
    yield put(chatConversationMessagesList.markMessagesSeen())
  }
}

function* watchOnChatSeen() {
  yield takeEvery(chatSocketOnChatSeen as any, onChatSeen)
}

export function* seenSaga() {
  yield all([fork(watchOnChatSeen)])
}
