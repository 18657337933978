import axios from 'axios'
import { ChatModel } from '../types/models'
import { SendMessageToUserDTO } from './dto'

class ChatsUsersAPI {
  // Send message to user
  sendMessageToUser = async (
    id: number,
    data: SendMessageToUserDTO,
    activeChat: ChatModel | null
  ) => {
    return axios.post(`api/chats/users/${id}/messages/${activeChat?.id}`, data)
  }

  // Get messages from user
  getUserMessages = async (id: number, activeChat: ChatModel, params: any) => {
    return axios.get(`api/chats/users/${id}/messages/${activeChat.id}`, {
      params,
    })
  }

  // Make seen massages in chat with user
  seenUserMessages = async (id: number | undefined) => {
    return axios.get(`api/chats/${id}/messages/seen`, {})
  }
}

export const chatsUsersAPI = new ChatsUsersAPI()
