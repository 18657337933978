import React from 'react'

interface IProps {
  color: string
  size?: number
  className?: string
}

export const HeartOutline = ({ size = 24, color, ...props }: IProps) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 10C1.75 6.54824 4.54822 3.75 8 3.75C9.76772 3.75 11.3639 4.48438 12.5 5.66271C13.6361 4.48438 15.2323 3.75 17 3.75C20.4518 3.75 23.25 6.54824 23.25 10C23.25 13.0381 21.4668 15.8433 19.3454 17.9856C17.2168 20.1353 14.6191 21.7492 12.7365 22.3748C12.583 22.4259 12.417 22.4259 12.2635 22.3748C10.3809 21.7492 7.78321 20.1353 5.65457 17.9856C3.53322 15.8433 1.75 13.0381 1.75 10ZM8 5.25C5.37665 5.25 3.25 7.37666 3.25 10C3.25 12.4619 4.71678 14.9067 6.72043 16.9302C8.62163 18.8502 10.891 20.2727 12.5 20.8682C14.109 20.2727 16.3784 18.8502 18.2796 16.9302C20.2832 14.9067 21.75 12.4619 21.75 10C21.75 7.37666 19.6233 5.25 17 5.25C15.3941 5.25 13.9741 6.04621 13.1132 7.26872C12.9727 7.46821 12.744 7.5869 12.5 7.5869C12.256 7.5869 12.0273 7.46821 11.8868 7.26872C11.0259 6.04621 9.60588 5.25 8 5.25Z"
      fill={color}
    />
  </svg>
)
