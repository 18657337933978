import React from 'react'
import { UnstyledButton } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { ChatModel } from '@/features/chat/types/models'
import { useUser } from '@/features/user'
import { Body } from './Body'
import useStyles from './Item.styles'

interface IProps {
  data: ChatModel
  onSelect: (data: ChatModel) => void
  active: boolean
}

export const Item = ({ data, active, onSelect }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  const { classes, cx } = useStyles()

  const { participant } = data

  const { isClient } = useUser()
  /*
  const config = {
    avatar: isClient(),
  }*/

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (active) {
      return
    }

    onSelect(data)
  }

  return (
    <UnstyledButton
      className={cx(classes.root, {
        [`${classes.rootActive}`]: active,
      })}
      onClick={onClick}
    >
      <div className={classes.avatar}>
        <AvatarProvider size={mobileView ? 42 : 48} avatar={participant?.avatar} />
      </div>

      <div className={classes.body}>
        <Body data={data} />
      </div>
    </UnstyledButton>
  )
}
